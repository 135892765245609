/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  roofStencil,
  link,
  check,
  roof,
  circleAdd,
  circleDelete,
  circleEdit,
  errorCircle,
  // layerCollapsed,
  // layerOpen,
} from '../../assets';
import {
  assetMapColors,
  colors,
  boxShadows,
  fonts,
  messageColors,
} from '../../styles/variables';
import {
  // DateFormat,
  FormatCurrency,
  IsNegative,
  RoundValues,
} from '../../utils';

const AssetMapBox = ({
  instrument,
  index,
  direction,
  fullWidth = false,
  side,
  onClick,
  multiSelectOnClick,
  currency,
  preferences,
  modalDisabled = false,
}) => {
  const [hasRiskalyze, setHasRiskalyze] = useState(false);
  const [riskalyzeImage, setRiskalyzeImage] = useState(false);
  const [hasMorningstarAWS, setHasMorningstarAWS] = useState(false);
  const [morningstarAWSImage, setMorningstarAWSImage] = useState(false);
  const [isStencil, setIsStencil] = useState(false);
  const [isResidence, setIsResidence] = useState(false);

  useEffect(() => {
    if (instrument) {
      const instrumentHasRiskalyze =
        instrument?.signpost_selected &&
        instrument?.signpost_selected === 'riskalyze' &&
        (preferences?.amr?.signpost === 'riskalyze' ||
          (Array.isArray(preferences?.amr?.signpost) &&
            preferences?.amr?.signpost.includes('riskalyze')));

      setHasRiskalyze(instrumentHasRiskalyze);
      if (instrumentHasRiskalyze) {
        setRiskalyzeImage(instrument?.signpost?.riskalyze);
      }

      const instrumentHasMorningstarAWS =
        instrument?.signpost_selected &&
        instrument?.signpost_selected === 'msaws' &&
        (preferences?.amr?.signpost === 'msaws' ||
          (Array.isArray(preferences?.amr?.signpost) &&
            preferences?.amr?.signpost.includes('msaws')));
      setHasMorningstarAWS(instrumentHasMorningstarAWS);
      if (instrumentHasMorningstarAWS) {
        setMorningstarAWSImage(instrument?.signpost?.msaws);
      }

      setIsStencil(instrument.status === 'stencil');
      setIsResidence(
        instrument.category === 'residence' && instrument.is_residence
      );
    }
  }, [instrument]);

  const generateBorder = (instrument) => {
    let borderColor = 'grey';
    let borderType = 'solid';
    if (instrument.instrument_type === 'liability') {
      borderColor = assetMapColors.liability;
    }
    if (instrument.instrument_type === 'insurance') {
      borderColor = assetMapColors.insurance;
    }
    if (instrument.instrument_type === 'income') {
      borderColor = assetMapColors.cashFlow;
    }
    if (instrument.instrument_type === 'asset') {
      if (instrument.is_annuity) {
        borderType = 'dashed';
      }
      if (instrument.category === 'retirement') {
        borderColor = assetMapColors.retirement;
      }
      if (instrument.category === 'nonretirement') {
        borderColor = assetMapColors.nonRetirement;
      }
      if (instrument.category === 'residence') {
        borderColor = assetMapColors.realEstate;
      }
    }
    if (instrument.status === 'stencil') {
      borderColor = assetMapColors.stencil;
    }
    return `1.5px ${borderType} ${borderColor}`;
  };

  const generateAssetMapItemMargin = (
    instrument,
    direction,
    isLinked,
    parentResidence,
    hasRiskalyze,
    hasMorningstarAWS
  ) => {
    const linkedLength = instrument?.linked_items?.length;
    if (direction === 'horizontal') {
      if (isLinked) {
        if (hasRiskalyze || hasMorningstarAWS) {
          return instrument.is_selected ? '4px 12px' : '-1px 12px 0 12px';
        }
        return instrument.is_selected ? '4px 6px' : '-1px 6px 0 6px';
      }
      if (instrument?.linked_items && linkedLength !== 0) {
        if (hasRiskalyze || hasMorningstarAWS) {
          return '3px 12px 0 12px';
        }
        if (instrument.is_rollup_parent && !instrument.is_expanded) {
          // const bottomMargin = linkedLength * 3 + 3;
          // return `3px 6px ${bottomMargin}px 6px`;
          return `3px 6px 10px 6px`;
        }

        return '3px 6px 0 6px';
      }
      if (instrument.category === 'residence' && instrument.is_residence) {
        if (hasRiskalyze || hasMorningstarAWS) {
          return '3px 12px 4px 12px';
        }
        return '3px 6px 4px 6px';
      }
      if (hasRiskalyze || hasMorningstarAWS) {
        return '4px 12px';
      }
      return '4px 6px';
    }
    if (direction === 'vertical') {
      if (isLinked && parentResidence) {
        return instrument.is_selected ? '4px' : '-1px 4px 0 4px';
      }
      if (
        instrument.category === 'residence' &&
        instrument.is_residence &&
        !isLinked
      ) {
        if (instrument?.linked_items && linkedLength !== 0) {
          return '6px 4px 0px 4px';
        }
        return '6px 4px 4px 4px';
      }
      if (instrument?.linked_items && linkedLength !== 0) {
        if (instrument.is_rollup_parent && !instrument.is_expanded) {
          // const bottomMargin = linkedLength * 3 + 3;
          // return `4px 0 ${bottomMargin}px 0`;
          return `4px 0 10px 0`;
        }
        return '4px 0 0 0';
      }
    }
    if (isLinked) {
      return instrument.is_selected ? '4px 0' : '-1px 0 0 0';
    }
    return '4px 0';
  };

  const generateAssetMapItemContent = (instrument, fullWidth) => {
    return (
      <>
        <InstrumentName
          style={{
            maxWidth: fullWidth ? 'auto' : '130px',
          }}
        >
          {instrument.name}
        </InstrumentName>
        <InstrumentSource style={{ maxWidth: fullWidth ? 'auto' : '130px' }}>
          {instrument.source}
          {!instrument.is_managed && '*'}
        </InstrumentSource>
        {!instrument.is_amount_hidden && (
          <InstrumentValue
            style={{
              color: checkNegative(instrument) ? messageColors.error : null,
              textDecoration:
                instrument.proposal_action === 'delete' ? 'line-through' : null,
            }}
          >
            {instrument.is_linked && (
              <LinkedImage
                src={link}
                alt="linked"
                data-image="instrument-linked"
              />
            )}
            {displayNegative(instrument)
              ? `(${FormatCurrency(generateValue(instrument), currency)})`
              : FormatCurrency(generateValue(instrument), currency)}
            {instrument?.amount_period_display &&
              instrument.amount_period_display}
            {instrument.instrument_type === 'income' && '/yr'}
            {instrument.show_cash_value &&
              !instrument.ignore_cash_value &&
              ' cv'}
            <LightText>
              {instrument.split_percent === 0
                ? `(${instrument.split_percent}%)`
                : instrument.split_percent &&
                  instrument.split_percent !== 100 &&
                  `(${instrument.split_percent}%)`}
              {instrument?.is_rollup_parent &&
                instrument?.percent !== 100 &&
                `(${instrument.percent}%)`}
            </LightText>
            {instrument.is_future_value &&
              instrument.instrument_type !== 'insurance' &&
              ' fv'}
            <LightText>
              {instrument.show_joint && !instrument.ignore_joint && '(JT)'}
            </LightText>
          </InstrumentValue>
        )}
        {/* {instrument?.is_rollup_parent && (
          <OwnershipPercent>{`${instrument.percent}%`}</OwnershipPercent>
        )} */}
        {/* {instrument?.is_rollup_parent && (
          <LinkedCount>
            <span>{instrument?.linked_items?.length}</span>
            {instrument?.is_expanded ? (
              <img src={layerOpen} alt="open" />
            ) : (
              <img src={layerCollapsed} alt="collapsed" />
            )}
          </LinkedCount>
        )} */}
      </>
    );
  };

  const generateItemStyling = (
    instrument,
    direction,
    fullWidth,
    isLinked,
    parentResidence = false,
    hasRiskalyze = false,
    hasMorningstarAWS = false
  ) => {
    return {
      margin: generateAssetMapItemMargin(
        instrument,
        direction,
        isLinked,
        parentResidence,
        hasRiskalyze,
        hasMorningstarAWS
      ),
      width: fullWidth ? 'calc(100% - 7px)' : null,
      maxWidth: fullWidth ? '100%' : null,
      minWidth: fullWidth ? 'auto' : null,
      zIndex: isLinked ? '0' : '1',
      border: generateBorder(instrument),
      // border:
      //   instrument.category === 'residence' && instrument.is_residence
      //     ? null
      //     : generateBorder(instrument),
      borderTop:
        instrument.category === 'residence' &&
        instrument.is_residence &&
        !isLinked
          ? '1.5px solid transparent'
          : generateBorder(instrument),
      borderRadius: instrument.instrument_type === 'income' ? '8px' : null,
      opacity: instrument.is_hidden ? '.5' : null,
      backgroundColor: instrument?.is_proposal
        ? assetMapColors.draft
        : instrument?.is_rollup_parent
          ? '#EDFCFF'
          : instrument.is_selected
            ? instrument.is_hidden
              ? assetMapColors.paleBlueHidden
              : colors.paleBlue
            : instrument.owned_by_another || instrument.owned_by_entity
              ? assetMapColors.otherOwner
              : null,
      transform: instrument.is_selected
        ? fullWidth
          ? 'scale(1.02)'
          : 'scale(1.05)'
        : null,
      // background: instrument.owned_by_another
      //   ? assetMapColors.otherOwner
      //   : instrument.owned_by_entity
      //   ? assetMapColors.entityOwner
      //   : null,
      boxShadow: instrument.is_selected
        ? instrument.is_hidden
          ? boxShadows.boxShadowAMRHiddenSelected
          : boxShadows.boxShadowAMRSelected
        : null,
      color:
        instrument.is_expected && !instrument.is_hidden
          ? colors.paleGrey
          : null,
      backgroundImage:
        instrument.is_proposal && instrument?.proposal_action === 'delete'
          ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23E24585' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
          : instrument.is_hidden
            ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
            : null,
    };
  };

  //Determine is instrument value is negative
  const checkNegative = (instrument) => {
    if (instrument.instrument_type === 'liability' && instrument.amount !== 0) {
      return true;
    } else {
      return IsNegative(instrument.amount);
    }
  };

  //Determine is box has negative display
  const displayNegative = (instrument) => {
    if (instrument.instrument_type === 'liability' && instrument.amount !== 0) {
      return true;
    } else {
      return false;
    }
  };

  //Generate value amount for asset-map box
  const generateValue = (instrument) => {
    let numberValue = instrument.amount;
    if (instrument.calculated || instrument.calculated === 0) {
      numberValue = instrument.calculated;
    }
    if (
      instrument.owned_by_another &&
      instrument.instrument_type === 'asset' &&
      !instrument.use_calculated
    ) {
      numberValue = instrument.amount;
    }
    return RoundValues(preferences?.amr?.rounding, numberValue);
  };

  //Generate asset-map box class name
  const generateClassName = (instrument, fullWidth) => {
    let className = `financial ${instrument.instrument_type}`;
    if (fullWidth) {
      className = `financial ${instrument.instrument_type}-joint`;
    }
    if (instrument.is_annuity) {
      className += ' annuity';
    }
    return className;
  };

  const checkDisplayLinked = (instrument) => {
    if (instrument?.is_rollup_parent) {
      return instrument?.is_expanded;
    } else {
      return instrument?.linked_items && instrument?.linked_items.length !== 0;
    }
  };

  const generateProposalIcon = (instrument, direction) => {
    switch (instrument?.proposal_action) {
      case 'add':
        instrument.label = `Created`;
        instrument.icon = circleAdd;
        break;
      case 'delete':
        instrument.label = `Deleted`;
        instrument.icon = circleDelete;
        break;
      case 'modify':
        instrument.label = `Edited`;
        instrument.icon = circleEdit;
        break;
      default:
        instrument.label = 'An unknown action has taken place.';
        instrument.icon = errorCircle;
    }
    return (
      <ProposalIconContainer
        title={instrument.label}
        direction={direction}
        isResidence={instrument.is_residence}
        className="proposal_icon"
        data-proposal-action={`proposal-${instrument?.action}-icon`}
      >
        <img src={instrument?.icon} alt={instrument?.action} />
      </ProposalIconContainer>
    );
  };

  return (
    <AssetMapItemParentContainer key={index} disabledModal={modalDisabled}>
      {instrument.is_selected && (
        <SelectedIconContainer
          title="Added to Search and Select List"
          selected={instrument.is_selected}
          direction={direction}
          isResidence={instrument.is_residence}
          onClick={() => multiSelectOnClick(instrument)}
          className="bulk_selected"
        >
          <img src={check} alt="selected" />
        </SelectedIconContainer>
      )}
      {instrument.is_proposal && generateProposalIcon(instrument, direction)}
      {isResidence ? (
        isStencil ? (
          <AssetMapItemContainer
            style={{
              marginBottom:
                instrument?.linked_items &&
                instrument?.linked_items.length !== 0
                  ? '4px'
                  : null,
            }}
          >
            <AssetMapItemRealEstateStencil
              style={generateItemStyling(instrument, direction, fullWidth)}
              data-is_hidden={instrument.is_hidden}
              data-is_stencil={true}
              className={generateClassName(instrument, fullWidth)}
              // title={DateFormat(
              //   instrument.as_of_date,
              //   preferences ? preferences.date_format : 'MM/DD/YYYY'
              // )}
              onClick={(e) => onClick(e, instrument)}
            >
              {generateAssetMapItemContent(instrument, fullWidth)}
            </AssetMapItemRealEstateStencil>
            {checkDisplayLinked(instrument) &&
              instrument.linked_items.map((linked, index) => {
                return (
                  <LinkedItemParentContainer key={index}>
                    {linked.is_selected && (
                      <SelectedIconContainer
                        title="Added to Search and Select List"
                        selected={linked.is_selected}
                        direction={direction}
                        isResidence={linked.is_residence}
                        onClick={() => multiSelectOnClick(instrument)}
                        className="bulk_selected"
                      >
                        <img src={check} alt="selected" />
                      </SelectedIconContainer>
                    )}
                    {linked.is_proposal &&
                      generateProposalIcon(linked, direction)}
                    <AssetMapItem
                      data-is_hidden={linked.is_hidden}
                      className={generateClassName(linked, fullWidth)}
                      style={generateItemStyling(
                        linked,
                        direction,
                        fullWidth,
                        true,
                        true,
                        hasRiskalyze,
                        hasMorningstarAWS
                      )}
                      onClick={(e) => onClick(e, linked)}
                    >
                      {generateAssetMapItemContent(linked, fullWidth)}
                    </AssetMapItem>
                  </LinkedItemParentContainer>
                );
              })}
          </AssetMapItemContainer>
        ) : (
          <AssetMapItemContainer
            style={{
              marginBottom:
                instrument?.linked_items &&
                instrument?.linked_items.length !== 0
                  ? '4px'
                  : null,
            }}
          >
            {hasRiskalyze && riskalyzeImage && side === 'west' && (
              <RiskalyzeImageInstrumentLeft
                src={riskalyzeImage}
                alt="Nitrogen score"
                data-image="riskalyze-score"
                style={{
                  right: direction === 'horizontal' ? '-11px' : null,
                }}
              />
            )}
            {hasMorningstarAWS && morningstarAWSImage && side === 'west' && (
              <MAWImageInstrumentLeft
                src={morningstarAWSImage}
                alt="Morningstar AWS score"
                data-image="msaws-score"
                style={{
                  right: direction === 'horizontal' ? '-11px' : null,
                }}
              />
            )}
            <AssetMapItemRealEstate
              style={generateItemStyling(
                instrument,
                direction,
                fullWidth,
                false,
                false,
                hasRiskalyze
              )}
              data-is_hidden={instrument.is_hidden}
              className={generateClassName(instrument, fullWidth)}
              onClick={(e) => onClick(e, instrument)}
              // title={DateFormat(
              //   instrument.as_of_date,
              //   preferences ? preferences.date_format : 'MM/DD/YYYY'
              // )}
            >
              {/* <AssetMapItemRealEstateInside> */}
              {generateAssetMapItemContent(instrument, fullWidth)}
              {/* </AssetMapItemRealEstateInside> */}
            </AssetMapItemRealEstate>
            {hasRiskalyze && riskalyzeImage && side === 'east' && (
              <RiskalyzeImageInstrumentRight
                src={riskalyzeImage}
                alt="Nitrogen score"
                data-image="riskalyze-score"
                style={{
                  left: direction === 'horizontal' ? '-11px' : null,
                }}
              />
            )}
            {hasMorningstarAWS && morningstarAWSImage && side === 'east' && (
              <MAWImageInstrumentLeft
                src={morningstarAWSImage}
                alt="Morningstar AWS score"
                data-image="msaws-score"
                style={{
                  left: direction === 'horizontal' ? '-11px' : null,
                }}
              />
            )}
            {checkDisplayLinked(instrument) &&
              instrument.linked_items.map((linked, index) => {
                return (
                  <LinkedItemParentContainer key={index}>
                    {linked.is_selected && (
                      <SelectedIconContainer
                        title="Added to Search and Select List"
                        selected={linked.is_selected}
                        direction={direction}
                        isResidence={linked.is_residence}
                        onClick={() => multiSelectOnClick(instrument)}
                        className="bulk_selected"
                      >
                        <img src={check} alt="selected" />
                      </SelectedIconContainer>
                    )}
                    {linked.is_proposal &&
                      generateProposalIcon(linked, direction)}
                    <AssetMapItem
                      data-is_hidden={linked.is_hidden}
                      className={generateClassName(linked, fullWidth)}
                      style={generateItemStyling(
                        linked,
                        direction,
                        fullWidth,
                        true,
                        true,
                        hasRiskalyze,
                        hasMorningstarAWS
                      )}
                      onClick={(e) => onClick(e, linked)}
                    >
                      {generateAssetMapItemContent(linked, fullWidth)}
                    </AssetMapItem>
                  </LinkedItemParentContainer>
                );
              })}
          </AssetMapItemContainer>
        )
      ) : (
        <AssetMapItemContainer
          style={{
            marginBottom:
              instrument?.linked_items && instrument?.linked_items.length !== 0
                ? '4px'
                : null,
          }}
        >
          {hasRiskalyze && riskalyzeImage && side === 'west' && (
            <RiskalyzeImageInstrumentLeft
              src={riskalyzeImage}
              data-image="riskalyze-score"
              alt="Nitrogen score"
              style={{ right: direction === 'horizontal' ? '-11px' : null }}
            />
          )}
          {hasMorningstarAWS && morningstarAWSImage && side === 'west' && (
            <MAWImageInstrumentLeft
              src={morningstarAWSImage}
              data-image="msaws-score"
              alt="Morningstar AWS score"
              style={{ right: direction === 'horizontal' ? '-11px' : null }}
            />
          )}
          <AssetMapItem
            style={generateItemStyling(
              instrument,
              direction,
              fullWidth,
              false,
              false,
              hasRiskalyze,
              hasMorningstarAWS
            )}
            // className={fullWidth ? 'insurance-joint' : null}
            className={generateClassName(instrument, fullWidth)}
            data-is_hidden={instrument.is_hidden}
            onClick={(e) => onClick(e, instrument)}
            // title={DateFormat(
            //   instrument.as_of_date,
            //   preferences ? preferences.date_format : 'MM/DD/YYYY'
            // )}
          >
            {generateAssetMapItemContent(instrument, fullWidth)}
          </AssetMapItem>
          {checkDisplayLinked(instrument) &&
            instrument.linked_items.map((linked, index) => {
              return (
                <LinkedItemParentContainer key={index}>
                  {linked.is_selected && (
                    <SelectedIconContainer
                      title="Added to Search and Select List"
                      selected={linked.is_selected}
                      direction={direction}
                      isResidence={linked.is_residence}
                      onClick={() => multiSelectOnClick(linked)}
                      className="bulk_selected"
                    >
                      <img src={check} alt="selected" />
                    </SelectedIconContainer>
                  )}
                  {linked.is_proposal &&
                    generateProposalIcon(linked, direction)}
                  <AssetMapItem
                    key={index}
                    data-is_hidden={linked.is_hidden}
                    className={generateClassName(linked, fullWidth)}
                    style={generateItemStyling(
                      linked,
                      direction,
                      fullWidth,
                      true,
                      false,
                      hasRiskalyze,
                      hasMorningstarAWS
                    )}
                    onClick={(e) => onClick(e, linked)}
                  >
                    {generateAssetMapItemContent(linked, fullWidth)}
                  </AssetMapItem>
                </LinkedItemParentContainer>
              );
            })}
          {hasRiskalyze && riskalyzeImage && side === 'east' && (
            <RiskalyzeImageInstrumentRight
              src={riskalyzeImage}
              data-image="riskalyze-score"
              alt="Nitrogen score"
              style={{ left: direction === 'horizontal' ? '-11px' : null }}
            />
          )}
          {hasMorningstarAWS && morningstarAWSImage && side === 'east' && (
            <MAWImageInstrumentRight
              src={morningstarAWSImage}
              data-image="msaws-score"
              alt="Morningstar AWS score"
              style={{ left: direction === 'horizontal' ? '-11px' : null }}
            />
          )}
          {instrument.is_rollup_parent && !checkDisplayLinked(instrument) && (
            <EntityRollupBackgroundAlt
              index={0}
              border={generateBorder(instrument)}
              borderRadius={
                instrument.instrument_type === 'income' ? '8px' : null
              }
              isEast={side === 'east'}
              isHorizontal={direction === 'horizontal'}
            />
          )}
          {/* {instrument.is_rollup_parent &&
            !checkDisplayLinked(instrument) &&
            instrument.linked_items.map((item, index) => {
              return (
                <EntityRollupBackground
                  key={index}
                  index={index}
                  border={generateBorder(instrument)}
                  borderRadius={
                    instrument.instrument_type === 'income' ? '8px' : null
                  }
                  isEast={side === 'east'}
                  isHorizontal={direction === 'horizontal'}
                />
              );
            })} */}
        </AssetMapItemContainer>
      )}
    </AssetMapItemParentContainer>
  );
};

// const EntityRollupBackground = styled.div`
//   background: #edfcff;
//   border: ${(props) => props.border};
//   border-radius: ${(props) => props.borderRadius};
//   z-index: ${(props) => (props.index === 0 ? '0' : `-${props.index}`)};

//   width: ${(props) =>
//     props.isHorizontal
//       ? `calc(100% - ${props.index + 15}px)`
//       : `calc(100% - ${props.index + 3}px)`};
//   height: calc(100% - 3px);
//   position: absolute;
//   left: ${(props) =>
//     props.isEast
//       ? props.index === 0
//         ? '3px'
//         : `${(props.index + 1) * 3}px`
//       : null};
//   right: ${(props) =>
//     props.isHorizontal
//       ? props.index === 0
//         ? '9px'
//         : `${props.index * 3 + 9}px`
//       : props.isEast
//       ? null
//       : props.index === 0
//       ? '3px'
//       : `${(props.index + 1) * 3}px`};
//   top: ${(props) => (props.index === 0 ? '3px' : `${(props.index + 1) * 3}px`)};
// `;

const EntityRollupBackgroundAlt = styled.div`
  background: white;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  z-index: 0;
  width: ${(props) =>
    props.isHorizontal ? `calc(100% - 21px)` : `calc(100% - 9px)`};
  height: calc(100% - 3px);
  position: absolute;
  left: ${(props) => (props.isHorizontal ? '9px' : '3px')};
  top: 5px;
`;

// const LinkedCount = styled.div`
//   position: absolute;
//   right: 5px;
//   bottom: 2px;
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   span {
//     font-size: 10px;
//     font-weight: ${fonts.semiBold};
//     margin-right: 2px;
//   }
//   img {
//     width: 8px;
//     height: 8px;
//   }
// `;

// const OwnershipPercent = styled.div`
//   position: absolute;
//   left: 5px;
//   bottom: 2px;
//   font-size: 10px;
//   font-weight: ${fonts.semiBold};
// `;

const SelectedIconContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: ${(props) => (props.direction === 'horizontal' ? '-2px' : '-10px')};
  top: ${(props) => (props.isResidence ? '8px' : '-2px')};
  background: white;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid ${colors.green};
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 9px;
    height: 9px;
  }
`;

const ProposalIconContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: ${(props) => (props.direction === 'horizontal' ? '-2px' : '-10px')};
  top: ${(props) => (props.isResidence ? '8px' : '-2px')};
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

const AssetMapItemParentContainer = styled.div`
  position: relative;
  &:active {
    ${({ disabledModal }) =>
      disabledModal &&
      css`
        transform: scale(1.1);
      `}
  }
`;

const LinkedItemParentContainer = styled.div`
  position: relative;
`;

const AssetMapItemContainer = styled.div`
  position: relative;
`;

const AssetMapItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 132px;
  min-width: 132px;
  max-width: 132px;
  padding: 5px 2px 4px 2px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: ${colors.lighterGrey};
  min-height: 43px;
  &:hover {
    box-shadow: ${boxShadows.boxShadow};
    transform: scale(1.01);
  }
`;

const AssetMapItemRealEstate = styled(AssetMapItem)`
  padding: 9px 1px 2px 3px;
  /* position: relative;
  width: 135px;
  max-width: 135px;
  height: 48px;
  background: ${assetMapColors.realEstate};
  clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0 20%); */
  &:before {
    content: '';
    display: block;
    height: 28px;
    background: transparent url(${roof}) no-repeat 0 0;
    background-size: 100% 100%;
    position: absolute;
    top: -10.5px;
    left: -2.5px;
    width: 141px;
    /* left: -3.5px;
    right: -3.5px; */
    z-index: 1;
  }
  /* &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: -10px;
    top: -13px;
    width: 122px;
    z-index: 1;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 18px solid ${assetMapColors.realEstate};
  } */
`;

const RiskalyzeImage = styled.img`
  height: 33px;
`;

const RiskalyzeImageInstrumentRight = styled(RiskalyzeImage)`
  position: absolute;
  left: -22px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
`;

const RiskalyzeImageInstrumentLeft = styled(RiskalyzeImage)`
  position: absolute;
  right: -22px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
`;

const MorningstarAWSImage = styled.img`
  height: 50px;
`;

const MAWImageInstrumentRight = styled(MorningstarAWSImage)`
  position: absolute;
  left: -36px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
`;

const MAWImageInstrumentLeft = styled(MorningstarAWSImage)`
  position: absolute;
  right: -36px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
`;

const InstrumentName = styled.p`
  font-weight: ${fonts.semiBold};
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
`;

const InstrumentSource = styled.p`
  font-weight: ${fonts.light};
  /* color: ${colors.paleGrey}; */
  font-size: 11px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InstrumentValue = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  line-height: 14px;
`;

const LinkedImage = styled.img`
  width: 10px;
  max-height: 10px;
  margin-right: 3px;
  opacity: 0.8;
`;

const AssetMapItemRealEstateStencil = styled(AssetMapItemRealEstate)`
  &:before {
    content: '';
    display: block;
    height: 28px;
    background: transparent url(${roofStencil}) no-repeat 0 0;
    background-size: 100% 100%;
    position: absolute;
    top: -10.5px;
    left: -2.5px;
    width: 141px;
    /* left: -3.5px;
    right: -3.5px; */
    z-index: 1;
  }
`;

const LightText = styled.span`
  font-weight: ${fonts.light};
  margin-left: 2px;
  font-size: 11px;
`;

AssetMapBox.propTypes = {
  instrument: PropTypes.object,
  index: PropTypes.number,
  direction: PropTypes.string,
  fullWidth: PropTypes.bool,
  side: PropTypes.string,
  onClick: PropTypes.func,
  multiSelectOnClick: PropTypes.func,
  currency: PropTypes.object,
  preferences: PropTypes.object,
  modalDisabled: PropTypes.bool,
};

export default AssetMapBox;
