import { apiAction, getCustomerStencilLibrary } from './';

export const HouseholdTypes = {
  UPDATE_HOUSEHOLD_STORE_VALUE: 'UPDATE_HOUSEHOLD_STORE_VALUE',

  //HOUSEHOLD INDEX
  GET_CONSUMER_HOUSEHOLDS: 'GET_CONSUMER_HOUSEHOLDS',
  GET_HOUSEHOLD_INDEX: 'GET_HOUSEHOLD_INDEX',
  GET_HOUSEHOLD_INDEX_LAYOUTS: 'GET_HOUSEHOLD_INDEX_LAYOUTS',
  CREATE_HOUSEHOLD_INDEX_LAYOUT: 'CREATE_HOUSEHOLD_INDEX_LAYOUT',
  UPDATE_HOUSEHOLD_INDEX_LAYOUT: 'UPDATE_HOUSEHOLD_INDEX_LAYOUT',
  DELETE_HOUSEHOLD_INDEX_LAYOUT: 'DELETE_HOUSEHOLD_INDEX_LAYOUT',
  SET_DEFAULT_HOUSEHOLD_INDEX_LAYOUT: 'SET_DEFAULT_HOUSEHOLD_INDEX_LAYOUT',
  GET_HOUSEHOLD_INDEX_CSV: 'GET_HOUSEHOLD_INDEX_CSV',
  CLEAR_HOUSEHOLD_INDEX_CSV: 'CLEAR_HOUSEHOLD_INDEX_CSV',

  //DELETED HOUSEHOLDS
  GET_DELETED_HOUSEHOLDS: 'GET_DELETED_HOUSEHOLDS',
  RESTORE_DELETED_HOUSEHOLDS: 'RESTORE_DELETED_HOUSEHOLDS',

  //DELETED HOUSEHOLD ITEMS
  GET_DELETED_HOUSEHOLD_ITEMS: 'GET_DELETED_HOUSEHOLD_ITEMS',
  RESTORE_DELETED_HOUSEHOLD_ITEMS: 'RESTORE_DELETED_HOUSEHOLD_ITEMS',

  //HOUSEHOLD BASIC MANAGEMENT
  GET_HOUSEHOLD_PERMISSION: 'GET_HOUSEHOLD_PERMISSION',
  GET_HOUSEHOLD_CONFIGS: 'GET_HOUSEHOLD_CONFIGS',
  GET_CONSUMER_HOUSEHOLD_ROLE: 'GET_CONSUMER_HOUSEHOLD_ROLE',
  GET_HOUSEHOLD_INFO: 'GET_HOUSEHOLD_INFO',
  SET_HOUSEHOLD_PERMISSION: 'SET_HOUSEHOLD_PERMISSION',
  SET_CAN_EDIT_HOUSEHOLD: 'SET_CAN_EDIT_HOUSEHOLD',
  SET_TEMP_HOUSEHOLD: 'SET_TEMP_HOUSEHOLD',
  REMOVE_HOUSEHOLD_INFO: 'REMOVE_HOUSEHOLD_INFO',
  CREATE_HOUSEHOLD_MANUAL: 'CREATE_HOUSEHOLD_MANUAL',
  REMOVE_CREATED_HOUSEHOLD: 'REMOVE_CREATED_HOUSEHOLD',
  UPDATE_LOCAL_HOUSEHOLD: 'UPDATE_LOCAL_HOUSEHOLD',
  UPDATE_HOUSEHOLD_INFO: 'UPDATE_HOUSEHOLD_INFO',
  GET_INVESTOR_PROFILE: 'GET_INVESTOR_PROFILE',
  UPDATE_INVESTOR_PROFILE: 'UPDATE_INVESTOR_PROFILE',
  DELETE_HOUSEHOLD: 'DELETE_HOUSEHOLD',
  SHOW_DELETE_MODAL: 'SHOW_DELETE_MODAL',
  TOGGLE_SHOW_BULK_MODAL: 'TOGGLE_SHOW_BULK_MODAL',
  TOGGLE_SHOW_CLONE_STENCIL_MODAL: 'TOGGLE_SHOW_CLONE_STENCIL_MODAL',

  //SHARING
  GET_HOUSEHOLD_SHARING_LIST: 'GET_HOUSEHOLD_SHARING_LIST',
  GET_HOUSEHOLD_SHARING_INVITE: 'GET_HOUSEHOLD_SHARING_INVITE',
  CREATE_HOUSEHOLD_SHARING_INVITE: 'CREATE_HOUSEHOLD_SHARING_INVITE',
  UPDATE_HOUSEHOLD_SHARING_INVITE: 'UPDATE_HOUSEHOLD_SHARING_INVITE',
  SHOW_SHARE_HOUSEHOLD_MODAL: 'SHOW_SHARE_HOUSEHOLD_MODAL',
  SHOW_CANCEL_SHARING_MODAL: 'SHOW_CANCEL_SHARING_MODAL',
  DELETE_HOUSEHOLD_SHARING_INVITE: 'DELETE_HOUSEHOLD_SHARING_INVITE',
  TOGGLE_SHOW_CONNECT_PREVIEW_MODAL: 'TOGGLE_SHOW_CONNECT_PREVIEW_MODAL',
  GET_HOUSEHOLD_ROLES: 'GET_HOUSEHOLD_ROLES',
  GET_ROLE_PERMISSIONS: 'GET_ROLE_PERMISSIONS',

  //HOUSEHOLD SUMMARY CALLS
  GET_HOUSEHOLD_ASSET_MAP_IMAGE: 'GET_HOUSEHOLD_ASSET_MAP_IMAGE',
  REFRESH_HOUSEHOLD_ASSET_MAP_IMAGE: 'REFRESH_HOUSEHOLD_ASSET_MAP_IMAGE',
  GET_STENCIL_ASSET_MAP_IMAGE: 'GET_STENCIL_ASSET_MAP_IMAGE',
  SYNC_INTEGRATION_HOUSEHOLD_SUMMARY: 'SYNC_INTEGRATION_HOUSEHOLD_SUMMARY',
  GET_HOUSEHOLD_KFFS: 'GET_HOUSEHOLD_KFFS',
  SET_HOUSEHOLD_KFFS: 'SET_HOUSEHOLD_KFFS',
  GET_HOUSEHOLD_NOTES: 'GET_HOUSEHOLD_NOTES',
  GET_BENEFICIARY_REPORT: 'GET_BENEFICIARY_REPORT',

  //MILESTONES
  GET_HOUSEHOLD_MILESTONES: 'GET_HOUSEHOLD_MILESTONES',
  UPDATE_HOUSEHOLD_MILESTONE: 'UPDATE_HOUSEHOLD_MILESTONE',
  TOGGLE_SHOW_MILESTONE_MODAL: 'TOGGLE_SHOW_MILESTONE_MODAL',

  //AMR LAYOUTS
  TOGGLE_SHOW_ASSET_MAP_LAYOUTS_MODAL: 'TOGGLE_SHOW_ASSET_MAP_LAYOUTS_MODAL',
  GET_ASSET_MAP_LAYOUTS: 'GET_ASSET_MAP_LAYOUTS',
  CREATE_ASSET_MAP_LAYOUT: 'CREATE_ASSET_MAP_LAYOUT',
  DELETE_ASSET_MAP_LAYOUT: 'DELETE_ASSET_MAP_LAYOUT',
  UPDATE_LAYOUT_MEMBERS: 'UPDATE_LAYOUT_MEMBERS',

  //AMR PROPOSALS
  TOGGLE_SHOW_PROPOSALS_MODAL: 'TOGGLE_SHOW_PROPOSALS_MODAL',
  GET_PROPOSAL_HOUSEHOLD_INFO: 'GET_PROPOSAL_HOUSEHOLD_INFO',
  TOGGLE_SHOW_PROPOSAL_MODE: 'TOGGLE_SHOW_PROPOSAL_MODE',
  GET_ASSET_MAP_PROPOSALS: 'GET_ASSET_MAP_PROPOSALS',
  GET_ASSET_MAP_PROPOSAL: 'GET_ASSET_MAP_PROPOSAL',
  CREATE_ASSET_MAP_PROPOSAL: 'CREATE_ASSET_MAP_PROPOSAL',
  UPDATE_ASSET_MAP_PROPOSAL: 'UPDATE_ASSET_MAP_PROPOSAL',
  DELETE_ASSET_MAP_PROPOSAL: 'DELETE_ASSET_MAP_PROPOSAL',
  CREATE_PROPOSAL_LEGAL: 'CREATE_PROPOSAL_LEGAL',
  READ_PROPOSAL_LEGAL: 'READ_PROPOSAL_LEGAL',
  UPDATE_PROPOSAL_LEGAL: 'UPDATE_PROPOSAL_LEGAL',
  DELETE_PROPOSAL_LEGAL: 'DELETE_PROPOSAL_LEGAL',
  TAKE_PROPOSAL_ACTION: 'TAKE_PROPOSAL_ACTION',

  //AMR STENCILS
  TOGGLE_SHOW_STENCIL_INFO_MODAL: 'TOGGLE_SHOW_STENCIL_INFO_MODAL',
  TOGGLE_SHOW_STENCIL_MODAL: 'TOGGLE_SHOW_STENCIL_MODAL',
  TOGGLE_SHOW_STENCIL_IMAGE_MODAL: 'TOGGLE_SHOW_STENCIL_IMAGE_MODAL',
  GET_STENCIL_OPTIONS: 'GET_STENCIL_OPTIONS',
  GET_STENCIL_MEMBERS: 'GET_STENCIL_MEMBERS',
  APPLY_STENCIL: 'APPLY_STENCIL',
  CLEAR_STENCIL: 'CLEAR_STENCIL',
  CLONE_STENCIL: 'CLONE_STENCIL',

  //REPORTS
  GET_HOUSEHOLD_REPORTS: 'GET_HOUSEHOLD_REPORTS',
  TOGGLE_SHOW_REPORT_DETAILS_MODAL: 'TOGGLE_SHOW_REPORT_DETAILS_MODAL',
  TOGGLE_SHOW_REPORT_SHARE_MODAL: 'TOGGLE_SHOW_REPORT_SHARE_MODAL',
  GET_REPORT_SHARE_TYPES: 'GET_REPORT_SHARE_TYPES',
  GET_REPORT_SHARE_TYPE_DETAILS: 'GET_REPORT_SHARE_TYPE_DETAILS',
  SHARE_REPORT: 'SHARE_REPORT',
  SHARED_REPORT_DETAILS: 'SHARED_REPORT_DETAILS',
  STOP_SHARING_REPORT: 'STOP_SHARING_REPORT',
  CREATE_ASSET_MAP_REPORT: 'CREATE_ASSET_MAP_REPORT',

  //SIGNALS
  TOGGLE_SHOW_SIGNAL_COMMENTS_MODAL: 'TOGGLE_SHOW_SIGNAL_COMMENTS_MODAL',
  GET_SIGNAL_COMMENTS: 'GET_SIGNAL_COMMENTS',
  CREATE_SIGNAL_COMMENT: 'CREATE_SIGNAL_COMMENT',
  DELETE_SIGNAL_COMMENT: 'DELETE_SIGNAL_COMMENT',
  TOGGLE_SNOOZE_SIGNAL: 'TOGGLE_SNOOZE_SIGNAL',
  TOGGLE_SHOW_SIGNAL_DETAILS_MODAL: 'TOGGLE_SHOW_SIGNAL_DETAILS_MODAL',

  //SURVEYS
  GET_SURVEYS: 'GET_SURVEYS',
  GET_SURVEY_HISTORY: 'GET_SURVEY_HISTORY',
  CREATE_SURVEY_INSTANCE: 'CREATE_SURVEY_INSTANCE',
  GET_SURVEY_INSTANCE: 'GET_SURVEY_INSTANCE',
  GET_LATEST_SURVEY_INSTANCE: 'GET_LATEST_SURVEY_INSTANCE',
  TOGGLE_SHOW_SURVEY_RESPONSES_MODAL: 'TOGGLE_SHOW_SURVEY_RESPONSES_MODAL',

  //DATA LOADER
  DOWNLOAD_CSV_FILE: 'DOWNLOAD_CSV_FILE',
  SET_UPLOAD_FILE: 'SET_UPLOAD_FILE',
  UPLOAD_CSV_FILE: 'UPLOAD_CSV_FILE',
  CLEAR_UPLOAD_CSV_RESULTS: 'CLEAR_UPLOAD_CSV_RESULTS',

  //INTEGRATIONS
  GET_LINKED_INTEGRATION_MEMBERS: 'GET_LINKED_INTEGRATION_MEMBERS',
  SEARCH_HOUSEHOLD_INTEGRATION: 'SEARCH_HOUSEHOLD_INTEGRATION',
  LINK_HH_MEMBER_TO_INTEGRATION: 'LINK_HH_MEMBER_TO_INTEGRATION',
  UNLINK_HH_MEMBER_FROM_INTEGRATION: 'UNLINK_HH_MEMBER_FROM_INTEGRATION',
  UNLINK_SALESFORCE_MEMBER: 'UNLINK_SALESFORCE_MEMBER',
  SYNC_HOUSEHOLD_INTEGRATION: 'SYNC_HOUSEHOLD_INTEGRATION',
  ALIGN_HOUSEHOLD_SALESFORCE: 'ALIGN_HOUSEHOLD_SALESFORCE',
  SYNC_HOUSEHOLD_INTEGRATIONS: 'SYNC_HOUSEHOLD_INTEGRATIONS',
  SYNC_HOUSEHOLD_MODAL: 'SYNC_HOUSEHOLD_MODAL',
  BULK_SYNC_HOUSEHOLD_INTEGRATIONS: 'BULK_SYNC_HOUSEHOLD_INTEGRATIONS',
  DELAY_AUTO_SYNC_INTEGRATIONS: 'DELAY_AUTO_SYNC_INTEGRATIONS',

  //FILE STORAGE HOUSEHOLD INFO
  GET_HOUSEHOLD_FOLDER_OPTIONS: 'GET_HOUSEHOLD_FOLDER_OPTIONS',
  LINK_HOUSEHOLD_FILE_STORAGE: 'LINK_HOUSEHOLD_FILE_STORAGE',
  UNLINK_HOUSEHOLD_FILE_STORAGE: 'UNLINK_HOUSEHOLD_FILE_STORAGE',

  //FILE STORAGE HOUSEHOLD SUMMARY/PANEL
  GET_HOUSEHOLD_FOLDER_GOOGLE: 'GET_HOUSEHOLD_FOLDER_GOOGLE',
  SET_HOUSEHOLD_FOLDER_GOOGLE_FAIL: 'SET_HOUSEHOLD_FOLDER_GOOGLE_FAIL',
  GET_HOUSEHOLD_FOLDER_DROPBOX: 'GET_HOUSEHOLD_FOLDER_DROPBOX',
  SET_HOUSEHOLD_FOLDER_DROPBOX_FAIL: 'SET_HOUSEHOLD_FOLDER_DROPBOX_FAIL',
  GET_HOUSEHOLD_FOLDER_BOX: 'GET_HOUSEHOLD_FOLDER_BOX',
  SET_HOUSEHOLD_FOLDER_BOX_FAIL: 'SET_HOUSEHOLD_FOLDER_BOX_FAIL',
  GET_HOUSEHOLD_FOLDER: 'GET_HOUSEHOLD_FOLDER',
  REFRESH_FILE_STORAGE: 'REFRESH_FILE_STORAGE',

  //FILE STORAGE UPLOAD FILE
  TOGGLE_SHOW_UPLOAD_FILE_MODAL: 'TOGGLE_SHOW_UPLOAD_FILE_MODAL',
  UPLOAD_FILE_STORAGE_FILE: 'UPLOAD_FILE_STORAGE_FILE',
  SET_FILE_STORAGE_UPLOAD_FAILED: 'SET_FILE_STORAGE_UPLOAD_FAILED',

  //DISCOVERY
  UPDATE_HOUSEHOLD_ADVISOR: 'UPDATE_HOUSEHOLD_ADVISOR',

  //MODAL MODE
  SET_MODAL_STATE: 'SET_MODAL_STATE',

  //HOUSEHOLD LEGAL
  TOGGLE_SHOW_LEGAL_MODAL: 'TOGGLE_SHOW_LEGAL_MODAL',
  GET_LEGALS: 'GET_LEGALS',
  CREATE_LEGAL: 'CREATE_LEGAL',
  GET_LEGAL: 'GET_LEGAL',
  UPDATE_LEGAL: 'UPDATE_LEGAL',
  DELETE_LEGAL: 'DELETE_LEGAL',
  CLEAR_LEGAL: 'CLEAR_LEGAL',
  GET_MEMBERS_LEGALS: 'GET_MEMBERS_LEGALS',

  //FAMILY MAPS
  GET_FAMILY_MAPS: 'GET_FAMILY_MAPS',
  GET_FAMILY_MAP: 'GET_FAMILY_MAP',
  CREATE_FAMILY_MAP: 'CREATE_FAMILY_MAP',
  UPDATE_FAMILY_MAP: 'UPDATE_FAMILY_MAP',
  DELETE_FAMILY_MAP: 'DELETE_FAMILY_MAP',
};

/*
  GENERIC FUNCTION USED TO CHANGE A MODIFY SINGLE VALUE IN HOUSEHOLD STORE
  - fieldName <string>: field name in redux store to modify
  - value <any>: new value desired for field
*/
export const updateHouseholdStoreValue = (fieldName, value) => ({
  type: HouseholdTypes.UPDATE_HOUSEHOLD_STORE_VALUE,
  payload: { fieldName, value },
});

let url;
process.env.NODE_ENV === 'development'
  ? (url = 'http://localhost:8000')
  : (url = window.location.origin);

//ACTION TO SET HOUSEHOLD INDEX IN STORE <object>
export const setHouseholdIndex = (householdIndex) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_INDEX,
  payload: householdIndex,
});

/*
  API CALL TO GET HOUSEHOLD INDEX
  - group <string>: id of user group to search
  - limit <integer>: number of results to return
  - offset <integer>: number of results to offset
  - query <string/object>: if string simple name search, otherwise nested object
  - sort <array>: list of columns to sort by
*/
export const getHouseholdIndex = (
  group = 'me',
  limit = 200,
  offset = null,
  query = '',
  sort = ['name']
) => {
  let params = {
    group,
    limit,
    offset,
    query,
    sort,
  };
  return apiAction({
    path: `/household/list`,
    method: 'POST',
    data: params,
    onSuccess: setHouseholdIndex,
    label: HouseholdTypes.GET_HOUSEHOLD_INDEX,
  });
};

//ACTION TO SET DELETED HOUSEHOLDS IN STORE <object>
export const setDeletedHouseholds = (deletedHouseholds) => ({
  type: HouseholdTypes.GET_DELETED_HOUSEHOLDS,
  payload: deletedHouseholds,
});

/*
  API CALL TO GET DELETED HOUSEHOLDS
  - path <string>: what path (member/customer) is needed for the API call
  - id <string>: user or customer id who is requesting
  - order <array>: determines sort order of results
  - limit <integer>: number of results to return
  - offset <integer>: number of results to offset
*/
export const getDeletedHouseholds = (
  path = 'member',
  id,
  order = ['modified_utc'],
  limit = 200,
  offset = 0
) => {
  return apiAction({
    path: `/${path}/${id}/deleted_households?offset=${offset}&ordering=${order}&limit=${limit}`,
    method: 'GET',
    onSuccess: setDeletedHouseholds,
    label: HouseholdTypes.GET_DELETED_HOUSEHOLDS,
  });
};

/*
  API CALL TO RESTORE DELETED HOUSEHOLDS
  - path <string>: what path (member/customer) is needed for the API call
  - id <string>: user or customer id who is requesting
  - householdIds <array>: list of households ids to be restored
*/
export const restoreDeletedHouseholds = (path = 'member', id, householdIds) => {
  return apiAction({
    path: `/household/restore_household`,
    method: 'PUT',
    data: { accessor: path, accessor_id: id, household_ids: householdIds },
    passId: path,
    passSubId: id,
    onSuccess: getDeletedHouseholds,
    successMessage:
      householdIds.length === 1 ? 'Household Restored' : 'Households Restored',
    label: HouseholdTypes.RESTORE_DELETED_HOUSEHOLDS,
  });
};

//ACTION TO SET DELETED HOUSEHOLDS ITEMS IN STORE <object>
export const setDeletedHouseholdItems = (deletedItems) => ({
  type: HouseholdTypes.GET_DELETED_HOUSEHOLD_ITEMS,
  payload: deletedItems,
});

/*
  API CALL TO GET DELETED HOUSEHOLDS ITEMS
  - householdId <string>: id of household to request deleted items
  - type <string>: what type of content to fetch (members/financials)
  - order <string>: determines sort order of results
  - limit <integer>: number of results to return
  - offset <integer>: number of results to offset
*/
export const getDeletedHouseholdItems = (
  householdId,
  isConsumer = false,
  type = 'household_members',
  offset = 0,
  order = '-modified_utc',
  limit = 25
) => {
  return apiAction({
    path: `/household/${householdId}/deleted_items?type=${type}&offset=${offset}&ordering=${order}&limit=${limit}`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setDeletedHouseholdItems,
    label: HouseholdTypes.GET_DELETED_HOUSEHOLD_ITEMS,
  });
};

/*
  API CALL TO RESTORE DELETED HOUSEHOLDS ITEMS
  - householdId <string>: id of household to request deleted items
  - householdMemIds <array>: list of household member ids to restore
  - instrumentIds <array>: list of instrument ids to be restored
  - type <string>: what type of content to fetch (members/financials)
*/
export const restoreDeletedHouseholdItems = (
  householdId,
  householdMemIds,
  instrumentIds,
  type,
  isConsumer = false
) => {
  let count = 0;
  type === 'household_members'
    ? (count = householdMemIds?.length)
    : (count = instrumentIds?.length);

  let displayText = '';
  if (type === 'household_members') {
    count === 1 ? (displayText = 'Member') : (displayText = 'Members');
  } else {
    count === 1 ? (displayText = 'Financial') : (displayText = 'Financials');
  }
  return apiAction({
    path: `/household/${householdId}/restore_deleted_items`,
    isConsumer: isConsumer,
    method: 'PUT',
    data: { hm_ids: householdMemIds, i_ids: instrumentIds },
    passId: householdId,
    passSubId: isConsumer,
    passThirdParam: type,
    onSuccess: getDeletedHouseholdItems,
    successMessage: `${count} ${displayText} Restored`,
    label: HouseholdTypes.RESTORE_DELETED_HOUSEHOLD_ITEMS,
  });
};

//ACTION TO SET CONSUMER HOUSEHOLDS IN STORE (CONNECT) <object>
export const setConsumerHouseholds = (households) => ({
  type: HouseholdTypes.GET_CONSUMER_HOUSEHOLDS,
  payload: households,
});

//API CALL TO GET CONSUMER HOUSEHOLDS
export const getConsumerHouseholds = () => {
  return apiAction({
    path: `/households`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setConsumerHouseholds,
    label: HouseholdTypes.GET_CONSUMER_HOUSEHOLDS,
  });
};

//ACTION TO SET HOUSEHOLD INDEX LAYOUTS IN STORE <array>
export const setHouseholdIndexLayouts = (layouts) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_INDEX_LAYOUTS,
  payload: layouts,
});

//API CALL TO GET LIST OF HOUSEHOLD INDEX LAYOUTS
export const getHouseholdIndexLayouts = () => {
  return apiAction({
    path: `/member/layouts/household-index`,
    method: 'GET',
    onSuccess: setHouseholdIndexLayouts,
    label: HouseholdTypes.GET_HOUSEHOLD_INDEX_LAYOUTS,
  });
};

/*
  API CALL TO CREATE HOUSEHOLD INDEX LAYOUT
  - layout <object>: content of layout to be created
*/
export const createHouseholdIndexLayout = (layout) => {
  let successAction;
  layout.data.is_default
    ? (successAction = setDefaultHouseholdIndexLayout)
    : (successAction = getHouseholdIndexLayouts);
  return apiAction({
    path: `/member/layouts/household-index`,
    method: 'POST',
    data: layout,
    onSuccess: successAction,
    successMessage: 'Layout Created',
    label: HouseholdTypes.CREATE_HOUSEHOLD_INDEX_LAYOUT,
  });
};

/*
  API CALL TO UPDATE HOUSEHOLD INDEX LAYOUT
  - layout <object>: content of layout to be updated
*/
export const updateHouseholdIndexLayout = (layout) => {
  return apiAction({
    path: `/member/layouts/household-index/${layout.id}`,
    method: 'PUT',
    data: layout,
    onSuccess: getHouseholdIndexLayouts,
    successMessage: 'Layout Updated',
    label: HouseholdTypes.UPDATE_HOUSEHOLD_INDEX_LAYOUT,
  });
};

/*
  API CALL TO DELETE HOUSEHOLD INDEX LAYOUT
  - layoutId <string>: id of layout to be deleted
*/
export const deleteHouseholdIndexLayout = (layoutId) => {
  return apiAction({
    path: `/member/layouts/household-index/${layoutId}`,
    method: 'DELETE',
    data: { id: layoutId },
    onSuccess: getHouseholdIndexLayouts,
    successMessage: 'Layout Deleted',
    label: HouseholdTypes.DELETE_HOUSEHOLD_INDEX_LAYOUT,
  });
};

/*
  API CALL TO CREATE HOUSEHOLD INDEX LAYOUT
  - layout <object>: content of layout to toggled default
  - isDefault <boolean>: whether desired layout is to be set as default
*/
export const setDefaultHouseholdIndexLayout = (layout, isDefault = true) => {
  return apiAction({
    path: `/member/layouts/household-index/default`,
    method: 'PUT',
    data: { id: layout.id, is_default: isDefault },
    onSuccess: getHouseholdIndexLayouts,
    successMessage: `Layout ${isDefault ? 'set' : 'removed'} as Default`,
    label: HouseholdTypes.SET_DEFAULT_HOUSEHOLD_INDEX_LAYOUT,
  });
};

//ACTION TO SET HOUSEHOLD INDEX CSV IN STORE <object>
export const setHouseholdIndexCSV = (csv) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_INDEX_CSV,
  payload: csv,
});

/*
  API CALL TO GET HOUSEHOLD INDEX CSV
  - configs <object>: all params of current display used for download
*/
export const getHouseholdIndexCSV = (configs) => {
  return apiAction({
    path: `/household/list/csv`,
    method: 'POST',
    data: configs,
    onSuccess: setHouseholdIndexCSV,
    label: HouseholdTypes.GET_HOUSEHOLD_INDEX_CSV,
  });
};

//ACTION TO CLEAR HOUSEHOLD INDEX CSV FROM STORE
export const clearHouseholdIndexCSV = () => ({
  type: HouseholdTypes.CLEAR_HOUSEHOLD_INDEX_CSV,
});

//ACTION TO SET HOUSEHOLD PERMISSION IN STORE <string>
export const setHouseholdPermission = (permission) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_PERMISSION,
  payload: permission,
});

/*
  API CALL TO GET HOUSEHOLD PERMISSION
  - id <string>: id of household to check
*/
export const getHouseholdPermission = (id) => {
  return apiAction({
    path: `/household/${id}/permission`,
    method: 'GET',
    onSuccess: setHouseholdPermission,
    label: HouseholdTypes.GET_HOUSEHOLD_PERMISSION,
  });
};

//ACTION TO SET HOUSEHOLD CONFIGS IN STORE <object>
export const setHouseholdConfigs = (configs) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_CONFIGS,
  payload: configs,
});

/*
  API CALL TO GET HOUSEHOLD CONFIGS
  - id <string>: id of household to check
  - isConsumer <boolean>: whether fetching for consumer
*/
export const getHouseholdConfigs = (id, isConsumer = true) => {
  return apiAction({
    path: `/household/${id}/config`,
    method: 'GET',
    isConsumer: isConsumer,
    onSuccess: setHouseholdConfigs,
    label: HouseholdTypes.GET_HOUSEHOLD_CONFIGS,
  });
};

//ACTION TO SET CONSUMER HOUSEHOLD ROLE IN STORE <string>
export const setConsumerHouseholdRole = (role) => ({
  type: HouseholdTypes.GET_CONSUMER_HOUSEHOLD_ROLE,
  payload: role,
});

/*
  API CALL TO GET CONSUMER HOUSEHOLD ROLE
  - id <string>: id of household to check
*/
export const getConsumerHouseholdRole = (id) => {
  return apiAction({
    path: `/household/${id}/role`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setConsumerHouseholdRole,
    label: HouseholdTypes.GET_CONSUMER_HOUSEHOLD_ROLE,
  });
};

//ACTION TO SET HOUSEHOLD INFO IN STORE <object>
export const setHouseholdInfo = (household) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_INFO,
  payload: household,
});

/*
  API CALL TO GET HOUSEHOLD INFO
  - id <string>: id of household to fetch
*/
export const getHouseholdInfo = (id) => {
  return apiAction({
    path: `/household/${id}/info`,
    method: 'GET',
    onSuccess: setHouseholdInfo,
    label: HouseholdTypes.GET_HOUSEHOLD_INFO,
  });
};

//ACTION TO SET HOUSEHOLD PERMISSION IN STORE <boolean>
export const setHasHouseholdPermission = (hasPermission) => ({
  type: HouseholdTypes.SET_HOUSEHOLD_PERMISSION,
  payload: hasPermission,
});

//ACTION TO SET CAN EDIT HOUSEHOLD IN STORE <boolean>
export const setCanEditHousehold = (canEdit) => ({
  type: HouseholdTypes.SET_CAN_EDIT_HOUSEHOLD,
  payload: canEdit,
});

/*
  API CALL TO GET HOUSEHOLD INFO
  - id <string>: id of household to fetch
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getConsumerHouseholdInfo = (id) => {
  return apiAction({
    path: `/household/${id}/info`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setHouseholdInfo,
    label: HouseholdTypes.GET_HOUSEHOLD_INFO,
  });
};

//ACTION TO SET HOUSEHOLD INFO FROM HOUSEHOLD INDEX <object>
export const setTempHousehold = (household) => ({
  type: HouseholdTypes.SET_TEMP_HOUSEHOLD,
  payload: household,
});

//ACTION TO REMOVE HOUSEHOLD INFO FROM STORE <string>
export const removeHouseholdInfo = (householdId) => ({
  type: HouseholdTypes.REMOVE_HOUSEHOLD_INFO,
  payload: householdId,
});

//ACTION TO SET NEWLY CREATED HOUSEHOLD INFO IN STORE <object>
export const setCreatedHouseholdManual = (household) => ({
  type: HouseholdTypes.CREATE_HOUSEHOLD_MANUAL,
  payload: household,
});

/*
  API CALL TO CREATE HOUSEHOLD MANUALLY
  - household <object>: content of household to be created
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const createHouseholdManual = (household, isConsumer = false) => {
  return apiAction({
    path: `/household/create/manual`,
    isConsumer: isConsumer,
    method: 'POST',
    data: household,
    onSuccess: setCreatedHouseholdManual,
    successMessage: 'Household Created',
    label: HouseholdTypes.CREATE_HOUSEHOLD_MANUAL,
  });
};

//ACTION TO REMOVE NEWLY CREATED HOUSEHOLD FROM STORE
export const removeCreatedHousehold = () => ({
  type: HouseholdTypes.REMOVE_CREATED_HOUSEHOLD,
});

/*
  ACTION TO UPDATE HOUSEHOLD INFO IN STORE WITHOUT API <object>
  - household <object>: content of household created
  - categories <object>: list of roles/categories needed for sorting
*/
export const updateLocalHousehold = (household, categories) => ({
  type: HouseholdTypes.UPDATE_LOCAL_HOUSEHOLD,
  payload: { household, categories },
});

/*
  API CALL TO UPDATE HOUSEHOLD INFO
  - id <string>: id of household to update
  - household <object>: data to be updates
*/
export const updateHouseholdInfo = (
  householdId,
  household,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/info`,
    method: 'PUT',
    isConsumer: isConsumer,
    data: household,
    passId: householdId,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    successMessage: 'Household Updated',
    label: HouseholdTypes.UPDATE_HOUSEHOLD_INFO,
  });
};

//ACTION TO SET HOUSEHOLD INVESTOR PROFILE IN STORE <object>
export const setHouseholdInvestorProfile = (investorProfile) => ({
  type: HouseholdTypes.GET_INVESTOR_PROFILE,
  payload: investorProfile,
});

/*
  API CALL TO GET HOUSEHOLD INVESTOR PROFILE INFO
  - id <string>: id of household to fetch
*/
export const getHouseholdInvestorProfile = (id) => {
  return apiAction({
    path: `/household/${id}/profile`,
    method: 'GET',
    onSuccess: setHouseholdInvestorProfile,
    label: HouseholdTypes.GET_INVESTOR_PROFILE,
  });
};

/*
  API CALL TO UPDATE HOUSEHOLD INVESTOR PROFILE INFO
  - id <string>: id of household to fetch
  - data <object>: data to be updated
*/
export const updateHouseholdInvestorProfile = (id, data) => {
  return apiAction({
    path: `/household/${id}/profile`,
    method: 'PUT',
    data: data,
    passId: id,
    successMessage: 'Household Updated',
    onSuccess: getHouseholdInvestorProfile,
    label: HouseholdTypes.UPDATE_INVESTOR_PROFILE,
  });
};

//ACTION TO UPDATE STORE THAT HOUSEHOLD HAS BEEN DELETED
export const deletedHousehold = () => ({
  type: HouseholdTypes.DELETE_HOUSEHOLD,
});

/*
  API CALL TO DELETE HOUSEHOLD
  - id <string>: id of household to delete
*/
export const deleteHousehold = (id, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/info`,
    isConsumer: isConsumer,
    method: 'DELETE',
    onSuccess: deletedHousehold,
    successMessage: 'Household Deleted',
    label: HouseholdTypes.DELETE_HOUSEHOLD,
  });
};

/*
  The display of the delete modal is done globally for a household
  - In order for it to work you must pass a type into the action that matches a key in this object
  - This is so additional fields can be added without having to pass more stuff into the action
  - E.g. If you want to show the delete modal for a member you would pass in ('member') for the type
    - Then it would match to this entry in the object and determine the display text and group
    - Group is used in Household.jsx generateDeleteModalContent() to determine the api endpoint
*/
const householdDeleteTypes = {
  member: { displayText: 'Member', group: 'member' },
  personal: { displayText: 'Personal Relationship', group: 'member' },
  entity: { displayText: 'Entity', group: 'member' },
  otherEntity: { displayText: 'Other Entity', group: 'member' },
  cashflow: { displayText: 'Cash Flow', group: 'instrument' },
  asset: { displayText: 'Asset', group: 'instrument' },
  liability: { displayText: 'Liability', group: 'instrument' },
  insurance: { displayText: 'Insurance', group: 'instrument' },
  targetmap: { displayText: 'Target-Map', group: 'targetmap' },
  targetmapWant: {
    displayText: 'Target-Map Want',
    apiValue: 'want',
    group: 'targetmap-instrument',
  },
  targetmapIncome: {
    displayText: 'Target-Map Cash Flow',
    apiValue: 'income',
    group: 'targetmap-instrument',
  },
  targetmapAsset: {
    displayText: 'Target-Map Asset',
    apiValue: 'asset',
    group: 'targetmap-instrument',
  },
  targetmapInsurance: {
    displayText: 'Target-Map Insurance',
    apiValue: 'insurance',
    group: 'targetmap-instrument',
  },
  signalComment: { displayText: 'Comment', group: 'signal-comment' },
  amrLayout: { displayText: 'Layout', group: 'amr-layout' },
  amrProposal: { displayText: 'Draft', group: 'amr-proposal' },
  legal: { displayText: 'Legal', group: 'legal' },
};

/*
  ACTION TO TOGGLE DISPLAY OF MULTI PURPOSE CONFIRM DELETE MODAL
  - showModal <boolean>: whether to show delete modal
  - type <string>: type of item being deleted (Member/Entity/Comment/Target-Map/want/income/asset/insurance)
  - name <string>: display name value of item to be deleted
  - id <string>: id of item to be deleted
  - subId <string>:  sub id of item to be deleted
*/
export const toggleShowDeleteModal = (
  showModal,
  type,
  name,
  id,
  subId,
  data
) => {
  let typeObject = {};
  if (householdDeleteTypes[type]) {
    typeObject = householdDeleteTypes[type];
  } else {
    typeObject = { displayText: type, group: type };
  }
  return {
    type: HouseholdTypes.SHOW_DELETE_MODAL,
    payload: {
      showModal,
      name,
      type,
      id,
      subId,
      typeObject,
      data,
    },
  };
};

const householdBulkTypes = {
  instruments: { singular: 'Financial', plural: 'Financials' },
  delete: 'delete',
  clone: 'clone',
  hide: 'hide',
  show: 'unhide',
};

/*
  ACTION TO TOGGLE DISPLAY OF MULTI PURPOSE CONFIRM BULK ACTION MODAL
  - showModal <boolean>: whether to show bulk modal
  - type <string>: type of item being modified (Member/Financials)
  - action <string>: type of item being modified (delete/clone/hide/show)
  - id <string>: id of item to be modified
  - subId <string>:  sub id of item to be modified
  - values <any>:  this can be any type, used for values to send in a body of a API call
*/
export const toggleShowBulkModal = (
  showModal,
  type,
  action,
  id,
  subId,
  values
) => {
  let typeObject = {};
  let actionObject = {};
  const isPlural = values?.length > 1;
  if (householdBulkTypes[type]) {
    typeObject = {
      value: type,
      label: isPlural
        ? householdBulkTypes[type].plural
        : householdBulkTypes[type].singular,
    };
  } else {
    typeObject = { value: type, label: isPlural ? `${type}s` : type };
  }
  if (householdBulkTypes[action]) {
    actionObject = { value: action, label: householdBulkTypes[action] };
  } else {
    actionObject = { value: action, label: action };
  }
  return {
    type: HouseholdTypes.TOGGLE_SHOW_BULK_MODAL,
    payload: {
      showModal,
      id,
      subId,
      typeObject,
      actionObject,
      values,
    },
  };
};

/*
  ACTION TO TOGGLE DISPLAY OF CONFIRM CLONE STENCIL PURPOSE CONFIRM BULK ACTION MODAL
  - showModal <boolean>: whether to show bulk modal
  - data <object>: stencil info object
*/
export const toggleShowCloneStencilModal = (showModal, data) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_CLONE_STENCIL_MODAL,
    payload: {
      showModal,
      data,
    },
  };
};

//ACTION TO SET HOUSEHOLD SHARING LIST IN STORE <object>
export const setHouseholdSharingList = (sharingList) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_SHARING_LIST,
  payload: sharingList,
});

/*
  API CALL TO GET HOUSEHOLD SHARING LIST
  - id <string>: id of household to fetch
*/
export const getHouseholdSharingList = (id) => {
  return apiAction({
    path: `/household/${id}/sharing`,
    method: 'GET',
    onSuccess: setHouseholdSharingList,
    label: HouseholdTypes.GET_HOUSEHOLD_SHARING_LIST,
  });
};

//ACTION TO SET HOUSEHOLD SHARING LIST IN STORE <object>
export const setHouseholdSharingInvite = (invite) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_SHARING_INVITE,
  payload: invite,
});

/*
  API CALL TO GET HOUSEHOLD MEMBER SHARING INVITE DETAILS
  - id <string>: id of household to fetch
  - memberId <string>: id of householdmember to fetch
*/
export const getHouseholdSharingInvite = (id, memberId) => {
  return apiAction({
    path: `/household/${id}/sharing/${memberId}`,
    method: 'GET',
    onSuccess: setHouseholdSharingInvite,
    label: HouseholdTypes.GET_HOUSEHOLD_SHARING_INVITE,
  });
};

/*
  API CALL TO CREATE HOUSEHOLD SHARING INVITE
  - id <string>: id of household
  - data <object>: details for creating invite
*/
export const createHouseholdSharingInvite = (id, data) => {
  return apiAction({
    path: `/household/${id}/sharing`,
    method: 'POST',
    data: data,
    onSuccess: setHouseholdSharingInvite,
    successMessage: 'Invite Created',
    label: HouseholdTypes.CREATE_HOUSEHOLD_SHARING_INVITE,
  });
};

/*
  API CALL TO UPDATE HOUSEHOLD SHARING INVITE
  - id <string>: id of household
  - memberId <string>: id of household member
  - data <object>: updated role value
*/
export const updateHouseholdSharingInvite = (id, memberId, data) => {
  return apiAction({
    path: `/household/${id}/sharing/${memberId}`,
    method: 'PUT',
    data: data,
    passId: id,
    passSubId: memberId,
    onSuccess: getHouseholdSharingInvite,
    successMessage: 'Invite Updated',
    label: HouseholdTypes.UPDATE_HOUSEHOLD_SHARING_INVITE,
  });
};

//ACTION TO TOGGLE DISPLAY OF SHARE HOUSEHOLD MODAL <boolean>
export const toggleShowShareHouseholdModal = (showModal, details = null) => {
  return {
    type: HouseholdTypes.SHOW_SHARE_HOUSEHOLD_MODAL,
    payload: { showModal, details },
  };
};

//ACTION TO TOGGLE DISPLAY OF CANCEL SHARING  MODAL <boolean>
export const toggleShowCancelSharingModal = (showModal, inviteDetails) => {
  return {
    type: HouseholdTypes.SHOW_CANCEL_SHARING_MODAL,
    payload: { showModal, inviteDetails },
  };
};

/*
  API CALL TO DELETE HOUSEHOLD SHARING INVITE
  - id <string>: id of household
  - memberId <string>: id of household member
  - isDelete <bool>: if for delete invite or stop sharing
*/
export const deleteHouseholdSharingInvite = (id, memberId, isDelete) => {
  return apiAction({
    path: `/household/${id}/sharing/${memberId}${isDelete ? '' : '/stop'}`,
    method: isDelete ? 'DELETE' : 'PUT',
    onSuccess: getHouseholdSharingInvite,
    passId: id,
    passSubId: memberId,
    successMessage: isDelete ? 'Invite Deleted' : 'Sharing Stopped',
    label: HouseholdTypes.DELETE_HOUSEHOLD_SHARING_INVITE,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF CONFIRM ENTER CONNECT PREVIEW MODE
  - showModal <boolean>: whether to show confirm modal
  - data <object>: invite and member info
*/
export const toggleShowConnectPreviewModal = (showModal, data) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_CONNECT_PREVIEW_MODAL,
    payload: {
      showModal,
      data,
    },
  };
};

//ACTION TO SET HOUSEHOLD ROLES IN STORE <array>
export const setHouseholdRoles = (roles) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_ROLES,
  payload: roles,
});

/*
  API CALL TO GET HOUSEHOLD ROLES
  - id <string>: id of household to fetch
  - systemOnly <boolean>: whether to only fetch system roles
*/
export const getHouseholdRoles = (id, systemOnly = false) => {
  return apiAction({
    path: `/household/${id}/connect/roles${systemOnly ? '?system=true' : ''}`,
    method: 'GET',
    onSuccess: setHouseholdRoles,
    label: HouseholdTypes.GET_HOUSEHOLD_ROLES,
  });
};

//ACTION TO SET ROLE'S PERMISSIONS VALUES IN STORE <array>
export const setRolePermissions = (permissions) => ({
  type: HouseholdTypes.GET_ROLE_PERMISSIONS,
  payload: permissions,
});

//Need ability to pass in only available/active
/*
  ACTION TO GET ROLE'S PERMISSIONS VALUES
  - id <string>: id of household to fetch
  - role <string>: value of the role
  - selectedOnly <boolean>: whether to show only selected permissions
*/
export const getRolePermissions = (id, role, selectedOnly = true) => {
  return apiAction({
    path: `/household/${id}/connect/roles/${role}${
      selectedOnly ? '?selected=true' : ''
    }`,
    method: 'GET',
    onSuccess: setRolePermissions,
    label: HouseholdTypes.GET_ROLE_PERMISSIONS,
  });
};

//ACTION TO SET HOUSEHOLD ASSET MAP IMAGE IN STORE <object>
export const setHouseholdAssetMapImage = (assetMapImage) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_ASSET_MAP_IMAGE,
  payload: assetMapImage,
});

/*
  API CALL TO GET HOUSEHOLD ASSET MAP IMAGE
  - id <string>: id of household to fetch
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getHouseholdAssetMapImage = (id, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/assetmap/image`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setHouseholdAssetMapImage,
    label: HouseholdTypes.GET_HOUSEHOLD_ASSET_MAP_IMAGE,
  });
};

/*
  API CALL TO GET HOUSEHOLD ASSET MAP IMAGE
  - id <string>: id of household to fetch
  - refresh <boolean/string>: whether image should be refreshed
  - isConsumer <boolean>: whether request is for consumer
  - isStencil <boolean>: whether image is stencil preview
*/
export const refreshHouseholdAssetMapImage = (
  id,
  refresh,
  isConsumer = false,
  isStencil = false
) => {
  let refreshValue = '';
  if (refresh || refresh === 'force') {
    refreshValue = `?refresh=${refresh}`;
  }
  return apiAction({
    path: `/household/${id}/assetmap/image${refreshValue}`,
    method: 'GET',
    showStart: true,
    isConsumer: isConsumer,
    onSuccess: isStencil ? setStencilAssetMapImage : setHouseholdAssetMapImage,
    label: HouseholdTypes.REFRESH_HOUSEHOLD_ASSET_MAP_IMAGE,
  });
};

//ACTION TO SET STENCIL ASSET MAP IMAGE IN STORE <object>
export const setStencilAssetMapImage = (assetMapImage) => ({
  type: HouseholdTypes.GET_STENCIL_ASSET_MAP_IMAGE,
  payload: assetMapImage,
});

/*
  API CALL TO GET STENCIL ASSET MAP IMAGE
  - id <string>: id of household to fetch
*/
export const getStencilAssetMapImage = (id, refresh) => {
  let refreshValue = '';
  if (refresh || refresh === 'force') {
    refreshValue = `?refresh=${refresh}`;
  }
  return apiAction({
    path: `/household/${id}/assetmap/image${refreshValue}`,
    method: 'GET',
    onSuccess: setStencilAssetMapImage,
    label: HouseholdTypes.GET_STENCIL_ASSET_MAP_IMAGE,
  });
};

/*
  API CALL TO SYNC INTEGRATION INFO FOR HOUSEHOLD SUMMARY
  - id <string>: id of household to fetch
  - integration <string>: key value of integration
*/
export const syncHouseholdSummary = (id, integration) => {
  return apiAction({
    path: `/household/${id}/integration/${integration}/sync`,
    method: 'PUT',
    passId: id,
    onSuccess: getHouseholdInfo,
    successMessage: 'Household Synced',
    errorMessage: 'Sync Failed',
    label: HouseholdTypes.SYNC_INTEGRATION_HOUSEHOLD_SUMMARY,
  });
};

//ACTION TO SET HOUSEHOLD KFFS IN STORE <object>
export const setHouseholdKFFs = (kffs) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_KFFS,
  payload: kffs,
});

/*
  API CALL TO GET HOUSEHOLD KFFs
  - id <string>: id of household to fetch
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getHouseholdKFFs = (id, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/kff`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setHouseholdKFFs,
    label: HouseholdTypes.GET_HOUSEHOLD_KFFS,
  });
};

//ACTION TO SET HOUSEHOLD PANEL KFFS IN STORE <object>
export const setKffPanelValues = (kffs) => ({
  type: HouseholdTypes.SET_HOUSEHOLD_KFFS,
  payload: kffs,
});

//ACTION TO SET HOUSEHOLD NOTES IN STORE <object>
export const setHouseholdNotes = (notes) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_NOTES,
  payload: notes,
});

/*
  API CALL TO GET HOUSEHOLD NOTES (MEMBER/INSTRUMENT/TARGETMAP)
  - id <string>: id of household to fetch
*/
export const getHouseholdNotes = (id) => {
  return apiAction({
    path: `/household/${id}/notes`,
    method: 'GET',
    onSuccess: setHouseholdNotes,
    label: HouseholdTypes.GET_HOUSEHOLD_NOTES,
  });
};

//ACTION TO SET BENEFICIARY IN STORE <object>
export const setBeneficiaryReport = (report) => ({
  type: HouseholdTypes.GET_BENEFICIARY_REPORT,
  payload: report,
});

/*
  API CALL TO GET BENEFICIARY REPORT
  - id <string>: id of household to fetch
*/
export const getBeneficiaryReport = (id) => {
  return apiAction({
    path: `/household/${id}/beneficiary`,
    method: 'GET',
    onSuccess: setBeneficiaryReport,
    label: HouseholdTypes.GET_BENEFICIARY_REPORT,
  });
};

/*
  API CALL TO GET BENEFICIARY REPORT FOR MEMBER
  - householdId <string>: id of household
  - memberId <string>: id of member to fetch
  - beneficiaryType <string>: which type of beneficiary to fetch (beneficiary=primary/contingent_beneficiary=contingent beneficiary)
*/
export const getBeneficiaryReportMember = (
  householdId,
  memberId,
  beneficiaryType = 'beneficiary'
) => {
  return apiAction({
    path: `/household/${householdId}/${beneficiaryType}/${memberId}`,
    method: 'GET',
    onSuccess: setBeneficiaryReport,
    label: HouseholdTypes.GET_BENEFICIARY_REPORT,
  });
};

//ACTION TO SET HOUSEHOLD MILESTONE IN STORE <object>
export const setHouseholdMilestones = (milestones) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_MILESTONES,
  payload: milestones,
});

/*
  API CALL TO GET HOUSEHOLD MILESTONEs
  - id <string>: id of household
*/
export const getHouseholdMilestones = (id, params) => {
  return apiAction({
    path: `/household/${id}/milestones`,
    method: 'GET',
    data: params,
    onSuccess: setHouseholdMilestones,
    label: HouseholdTypes.GET_HOUSEHOLD_MILESTONES,
  });
};

/*
  API CALL TO UPDATE HOUSEHOLD MILESTONE
  - id <string>: id of household
  - milestone <object>: data to updated
*/
export const updateHouseholdMilestone = (id, milestone, getParams = false) => {
  return apiAction({
    path: `/household/${id}/milestone`,
    method: 'POST',
    data: milestone,
    passId: id,
    successMessage: 'Milestone Updated',
    onSuccess: getHouseholdMilestones,
    passSubId: getParams,
    label: HouseholdTypes.UPDATE_HOUSEHOLD_MILESTONE,
  });
};

/*
  API CALL TO DISMISS HOUSEHOLD MILESTONE MODAL
  - id <string>: id of household
*/
export const dismissHouseholdMilestone = (id) => {
  return apiAction({
    path: `/household/${id}/milestone`,
    method: 'POST',
    data: { number: 0 },
    label: HouseholdTypes.UPDATE_HOUSEHOLD_MILESTONE,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF HOUSEHOLD SUMMARY MILESTONE MODAL
  - showModal <boolean>: whether to display modal
  - userOpened <boolean>: whether user opened modal or was displayed on load
*/
export const toggleShowMilestoneModal = (showModal, userOpened = false) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_MILESTONE_MODAL,
    payload: { showModal, userOpened },
  };
};

/*
  ACTION TO TOGGLE DISPLAY OF ASSET MAP LAYOUTS MODAL
  - showModal <boolean>: whether to display modal
*/
export const toggleShowAssetMapLayoutsModal = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_ASSET_MAP_LAYOUTS_MODAL,
    payload: showModal,
  };
};

//ACTION TO SET ASSET MAP LAYOUTS IN STORE <array>
export const setAssetMapLayouts = (layouts) => ({
  type: HouseholdTypes.GET_ASSET_MAP_LAYOUTS,
  payload: layouts,
});

/*
  ACTION TO GET ASSET MAP LAYOUTS
  - id <string>: id of household to fetch
  - isConsumer <bool>: if user is consumer
*/
export const getAssetMapLayouts = (id, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/assetmap/layout`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setAssetMapLayouts,
    label: HouseholdTypes.GET_ASSET_MAP_LAYOUTS,
  });
};

/*
  ACTION TO CREATE ASSET MAP LAYOUT
  - id <string>: id of household to create layout
  - layout <object>: data to create layout
  - isConsumer <bool>: if user is consumer
*/
export const createAssetMapLayout = (id, layout, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/assetmap/layout`,
    isConsumer: isConsumer,
    method: 'POST',
    data: layout,
    passId: id,
    passSubId: isConsumer,
    onSuccess: getAssetMapLayouts,
    successMessage: 'Layout Created',
    label: HouseholdTypes.CREATE_ASSET_MAP_LAYOUT,
  });
};

/*
  ACTION TO DELETE ASSET MAP LAYOUT
  - id <string>: id of household to delete layout
  - layoutId <string>: id of layout to delete
  - isConsumer <bool>: if user is consumer
*/
export const deleteAssetMapLayout = (id, layoutId, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/assetmap/layout/${layoutId}`,
    isConsumer: isConsumer,
    method: 'DELETE',
    passId: id,
    passSubId: isConsumer,
    onSuccess: getAssetMapLayouts,
    successMessage: 'Layout Deleted',
    label: HouseholdTypes.DELETE_ASSET_MAP_LAYOUT,
  });
};

//ACTION TO UPDATE SELECTED MEMBERS FOR ASSET MAP LAYOUT IN STORE <array>
export const updateLayoutMembers = (members) => ({
  type: HouseholdTypes.UPDATE_LAYOUT_MEMBERS,
  payload: members,
});

/*
  ACTION TO TOGGLE DISPLAY OF ASSET MAP PROPOSAL MODE
  - showModal <boolean>: whether to display mode
*/
export const toggleShowProposalsModal = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_PROPOSALS_MODAL,
    payload: showModal,
  };
};

//ACTION TO SET HOUSEHOLD INFO IN STORE <object>
export const setProposalHouseholdInfo = (household) => ({
  type: HouseholdTypes.GET_PROPOSAL_HOUSEHOLD_INFO,
  payload: household,
});

/*
  API CALL TO GET PROPOSAL HOUSEHOLD INFO
  - id <string>: uuid of household to fetch
  - proposalId <string>: uuid of proposal to get info from
*/
export const getProposalHouseholdInfo = (id, proposalId = null) => {
  return apiAction({
    path: `/household/${id}/proposal/${proposalId}/info`,
    method: 'GET',
    onSuccess: setProposalHouseholdInfo,
    label: HouseholdTypes.GET_PROPOSAL_HOUSEHOLD_INFO,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF ASSET MAP PROPOSALS MODAL
  - showModal <boolean>: whether to display modal
*/
export const toggleShowProposalMode = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_PROPOSAL_MODE,
    payload: showModal,
  };
};

//ACTION TO SET ASSET MAP PROPOSALS IN STORE <array>
export const setAssetMapProposals = (layouts) => ({
  type: HouseholdTypes.GET_ASSET_MAP_PROPOSALS,
  payload: layouts,
});

/*
  ACTION TO GET ASSET MAP PROPOSALS
  - id <string>: id of household to fetch
  - isConsumer <bool>: if user is consumer
*/
export const getAssetMapProposals = (id, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/proposal`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setAssetMapProposals,
    label: HouseholdTypes.GET_ASSET_MAP_PROPOSALS,
  });
};

//ACTION TO SET SINGLE ASSET MAP PROPOSAL IN STORE <object>
export const setAssetMapProposal = (proposal) => ({
  type: HouseholdTypes.GET_ASSET_MAP_PROPOSAL,
  payload: proposal,
});

/*
  ACTION TO GET ASSET MAP PROPOSAL
  - id <string>: id of household to fetch
  - proposalId <string>: id of proposal to fetch
  - isConsumer <bool>: if user is consumer
*/
export const getAssetMapProposal = (id, proposalId, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/proposal/${proposalId}`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setAssetMapProposal,
    label: HouseholdTypes.GET_ASSET_MAP_PROPOSAL,
  });
};

/*
  ACTION TO CREATE ASSET MAP PROPOSAL
  - id <string>: id of household to create proposal
  - proposal <object>: data to create proposal
  - isConsumer <bool>: if user is consumer
*/
export const createAssetMapProposal = (id, proposal, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/proposal`,
    isConsumer: isConsumer,
    method: 'POST',
    data: proposal,
    onSuccess: setAssetMapProposal,
    successMessage: 'Draft Created',
    label: HouseholdTypes.CREATE_ASSET_MAP_PROPOSAL,
  });
};

/*
  ACTION TO CREATE ASSET MAP PROPOSAL
  - id <string>: id of household to update proposal
  - proposal <object>: data to update proposal
  - showToast <bool>: whether toast message should show
  - isConsumer <bool>: if user is consumer
*/
export const updateAssetMapProposal = (
  id,
  proposal,
  showToast = true,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${id}/proposal/${proposal?.id}`,
    isConsumer: isConsumer,
    method: 'PUT',
    data: proposal,
    passId: id,
    passSubId: proposal?.id,
    onSuccess: getAssetMapProposal,
    errorMessage: 'Error updating draft',
    successMessage: showToast ? 'Draft Updated' : null,
    label: HouseholdTypes.UPDATE_ASSET_MAP_PROPOSAL,
  });
};

/*
  ACTION TO DELETE ASSET MAP PROPOSAL
  - id <string>: id of household to delete proposal
  - proposalId <string>: id of proposal to delete
  - isConsumer <bool>: if user is consumer
*/
export const deleteAssetMapProposal = (id, proposalId, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/proposal/${proposalId}`,
    isConsumer: isConsumer,
    method: 'DELETE',
    passId: id,
    passSubId: isConsumer,
    onSuccess: getAssetMapProposals,
    successMessage: 'Draft Deleted',
    label: HouseholdTypes.DELETE_ASSET_MAP_PROPOSAL,
  });
};

//ACTION TO SET PROPOSAL INSTRUMENT INFO IN STORE <object>
export const setProposalLegal = (legal) => ({
  type: HouseholdTypes.READ_PROPOSAL_LEGAL,
  payload: legal,
});

/*
  API CALL TO GET PROPOSAL OBJECT INFO
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal for instrument
  - proposalActionId <string>: uuid of proposal action be fetched
  - object_type <string> type of object you are grabbing
*/
export const readProposalLegal = (
  householdId,
  proposalId,
  proposalActionId
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action/${proposalActionId}`,
    method: 'GET',
    onSuccess: setProposalLegal,
    label: HouseholdTypes.READ_PROPOSAL_LEGAL,
  });
};

/*
  API CALL TO ACCEPT/REJECT PROPOSAL ACTION
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - proposalActionId <string>: uuid of proposal action to accept/reject
  - isAccept <boolean>: whether request is for accepting proposal
*/
export const takeProposalActionLegal = (
  householdId,
  proposalId,
  proposalActionId,
  isAccept
) => {
  return apiAction({
    path: `/proposal/action/${proposalActionId}/accept_reject`,
    method: 'PUT',
    data: { accept: isAccept },
    successMessage: isAccept ? 'Draft Accepted' : 'Draft Rejected',
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    showStart: true,
    label: HouseholdTypes.TAKE_PROPOSAL_ACTION,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF STENCIL INFO MODAL
  - showModal <boolean>: whether to show modal
*/
export const toggleShowStencilInfoModal = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_STENCIL_INFO_MODAL,
    payload: showModal,
  };
};

/*
  ACTION TO TOGGLE DISPLAY OF STENCIL SELECT MODAL
  - showModal <boolean>: whether to show modal
*/
export const toggleShowStencilModal = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_STENCIL_MODAL,
    payload: showModal,
  };
};

/*
  ACTION TO TOGGLE DISPLAY OF STENCIL IMAGE PREVIEW MODAL
  - showModal <boolean>: whether to show modal
  - stencil <object>: stencil details
*/
export const toggleShowStencilImagePreviewModal = (showModal, stencil) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_STENCIL_IMAGE_MODAL,
    payload: { showModal, stencil },
  };
};

//ACTION TO SET STENCILS IN THE STORE <array>
export const setStencilOptions = (stencils) => ({
  type: HouseholdTypes.GET_STENCIL_OPTIONS,
  payload: stencils,
});

/*
  API CALL TO GET ASSET MAP STENCIL OPTIONS
  - isConsumer <bool>: whether user is consumer
*/
export const getStencilOptions = (isConsumer = false) => {
  return apiAction({
    path: `/household/stencil_list`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setStencilOptions,
    label: HouseholdTypes.GET_STENCIL_OPTIONS,
  });
};

/*
  API CALL TO GET ASSET MAP LIBRARY STENCILS
  - library <string>: which library to fetch (assetmap/assetmap+/shared/personal)
*/
export const getStencilLibraryOptions = (library = 'assetmap') => {
  return apiAction({
    path: `/household/stencil_list/${library}`,
    method: 'GET',
    onSuccess: setStencilOptions,
    label: HouseholdTypes.GET_STENCIL_OPTIONS,
  });
};

//ACTION TO SET STENCIL MEMBERS IN STORE <array>
export const setStencilMembers = (members) => ({
  type: HouseholdTypes.GET_STENCIL_MEMBERS,
  payload: members,
});

/*
  API CALL TO GET ASSET MAP STENCIL MEMBERS
  - stencilId <string>: id of stencil to get members from
  - isConsumer <bool>: whether user is consumer
*/
export const getStencilMembers = (stencilId, isConsumer = false) => {
  return apiAction({
    path: `/household/${stencilId}/stencil/members`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setStencilMembers,
    label: HouseholdTypes.GET_STENCIL_MEMBERS,
  });
};

/*
  API CALL TO APPLY ASSET MAP STENCIL
  - householdId <string>: id of household
  - stencil <object>: data for stencil to apply
  - isConsumer <bool>: whether user is consumer
*/
export const applyStencil = (householdId, stencil, isConsumer = false) => {
  return apiAction({
    path: `/household/${householdId}/stencil/apply`,
    isConsumer: isConsumer,
    method: 'POST',
    data: stencil,
    passId: householdId,
    passSubId: isConsumer,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    showStart: true,
    successMessage: 'Stencil Applied',
    label: HouseholdTypes.APPLY_STENCIL,
  });
};

/*
  API CALL TO CLEAR ASSET MAP STENCIL
  - householdId <string>: id of household
  - isConsumer <bool>: whether user is consumer
*/
export const clearStencil = (householdId, isConsumer = false) => {
  return apiAction({
    path: `/household/${householdId}/stencil/clear`,
    isConsumer: isConsumer,
    method: 'PUT',
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    passId: householdId,
    passSubId: isConsumer,
    successMessage: 'Stencil Cleared',
    label: HouseholdTypes.CLEAR_STENCIL,
  });
};

/*
  API CALL TO CLONE ASSET MAP STENCIL
  - stencilId <string>: id of stencil to clone
  - isManager <boolean>: whether request is coming from manager
  - library <string>: library user is currently on
*/
export const cloneStencil = (
  stencilId,
  isManager = false,
  library = 'personal'
) => {
  return apiAction({
    path: `/household/${stencilId}/stencil/clone`,
    method: 'GET',
    passId: library,
    onSuccess: isManager ? getCustomerStencilLibrary : getStencilLibraryOptions,
    successMessage: 'Stencil Added',
    showStart: true,
    label: HouseholdTypes.CLONE_STENCIL,
  });
};

//ACTION TO SET HOUSEHOLD REPORTS IN STORE <array>
export const setHouseholdReports = (reports) => ({
  type: HouseholdTypes.GET_HOUSEHOLD_REPORTS,
  payload: reports,
});

/*
  API CALL TO GET HOUSEHOLD REPORTS
  - id <string>: id of household
  - params <object>: sort, filter, search params
*/
export const getHouseholdReports = (id, params, isConsumer = false) => {
  return apiAction({
    path: `/household/${id}/${isConsumer ? 'shared/' : ''}reports`,
    method: 'GET',
    data: params,
    isConsumer: isConsumer,
    onSuccess: setHouseholdReports,
    label: HouseholdTypes.GET_HOUSEHOLD_REPORTS,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF REPORT DETAILS MODAL
  - showModal <boolean>: whether to show modal
  - report <object>: report details content to be passed
*/
export const toggleShowReportDetailsModal = (showModal, report) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_REPORT_DETAILS_MODAL,
    payload: { showModal, report },
  };
};

/*
  ACTION TO TOGGLE DISPLAY OF REPORT SHARE MODAL
  - showModal <boolean>: whether to show modal
  - report <object>: report details content to be passed
*/
export const toggleShowReportShareModal = (showModal, report) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_REPORT_SHARE_MODAL,
    payload: { showModal, report },
  };
};

//ACTION TO SET HOUSEHOLD REPORT SHARE TYPES IN STORE <array>
export const setReportShareTypes = (reportShareTypes) => {
  return {
    type: HouseholdTypes.GET_REPORT_SHARE_TYPES,
    payload: { reportShareTypes },
  };
};

/*
  API CALL TO GET REPORT SHARE TYPES
  - id <boolean>: id of household
  - report <object>: report which details are being fetched
*/
export const getReportShareTypes = (id, report) => {
  return apiAction({
    path: `/household/${id}/reports/${report.id}/share/available`,
    method: 'GET',
    onSuccess: setReportShareTypes,
    label: HouseholdTypes.GET_REPORT_SHARE_TYPES,
  });
};

//ACTION TO SET RESPONSE FROM REPORT DETAIL SHARE TYPE */
export const setReportShareTypeDetails = (details) => {
  return {
    type: HouseholdTypes.GET_REPORT_SHARE_TYPE_DETAILS,
    payload: { details },
  };
};

/*
  API CALL TO GET REPORT SHARE DETAILS (ADDITIONAL INFO FOR REPORT SHARING)
  - id <boolean>: id of household
  - report <object>: report which details are being fetched
  - shareType <string>: method to share report (morningstar, etc)
*/
export const getReportShareTypeDetails = (id, report, shareType) => {
  return apiAction({
    path: `/household/${id}/reports/${report.id}/share/${shareType}`,
    method: 'GET',
    onSuccess: setReportShareTypeDetails,
    label: HouseholdTypes.GET_REPORT_SHARE_TYPE_DETAILS,
  });
};

//ACTION TO SET RESPONSE OF SHARED REPORT <object>
export const setSharedReportDetails = (response) => {
  return {
    type: HouseholdTypes.SHARED_REPORT_DETAILS,
    payload: { response },
  };
};

/*
  API CALL TO SHARE HOUSEHOLD REPORT
  - id <boolean>: id of household
  - report <object>: report which details are being fetched
  - shareType <string>: method to share report (morningstar, msaws, connect)
  - data <object>: body to be sent with request
*/
export const shareReport = (id, report, shareType, data = null) => {
  return apiAction({
    path: `/household/${id}/reports/${report?.id}/share/${shareType}`,
    method: 'POST',
    data,
    showStart: true,
    passId: id,
    passSubId: report,
    passThirdParam: 'connect',
    successMessage: `Report Shared`,
    label: HouseholdTypes.SHARE_REPORT,
    onSuccess:
      shareType === 'connect'
        ? getReportShareTypeDetails
        : setSharedReportDetails,
  });
};

/*
  API CALL TO STOP SHARING HOUSEHOLD REPORT
  - id <boolean>: id of household
  - report <object>: report which details are being fetched
  - shareId <string>: id of share object to be deleted
*/
export const stopSharingReport = (id, report, shareId) => {
  return apiAction({
    path: `/household/${id}/reports/${report?.id}/share/connect/${shareId}`,
    method: 'DELETE',
    passId: id,
    passSubId: report,
    passThirdParam: 'connect',
    label: HouseholdTypes.STOP_SHARING_REPORT,
    onSuccess: getReportShareTypeDetails,
  });
};

//ACTION TO UPDATE WHETHER TO CREATE ASSET MAP REPORT IN STORE <boolean>
export const createAssetMapReport = (shouldCreate) => ({
  type: HouseholdTypes.CREATE_ASSET_MAP_REPORT,
  payload: shouldCreate,
});

//ACTION TO TOGGLE DISPLAY OF SIGNAL DETAILS MODAL
export const toggleShowSignalCommentsModal = (showModal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_SIGNAL_COMMENTS_MODAL,
    payload: showModal,
  };
};

//ACTION TO SET SIGNAL COMMENTS IN THE STORE <array>
export const setSignalComments = (comments) => {
  return {
    type: HouseholdTypes.GET_SIGNAL_COMMENTS,
    payload: comments,
  };
};

/*
  API CALL TO GET SIGNAL COMMENTS
  - id <string>: id of household
  - signalType <string>: value of signal type
*/
export const getSignalComments = (id, signalType) => {
  return apiAction({
    path: `/household/${id}/signal/comment?signal_type=${signalType}`,
    method: 'GET',
    onSuccess: setSignalComments,
    label: HouseholdTypes.GET_SIGNAL_COMMENTS,
  });
};

/*
  API CALL TO CREATE SIGNAL COMMENT
  - id <string>: id of household
  - data <object>: comment data to be created
*/
export const createSignalComment = (id, data) => {
  return apiAction({
    path: `/household/${id}/signal/comment`,
    method: 'POST',
    data,
    passId: id,
    passSubId: data?.signal_type,
    onSuccess: getSignalComments,
    label: HouseholdTypes.CREATE_SIGNAL_COMMENT,
  });
};

/*
  API CALL TO DELETE SIGNAL COMMENT
  - householdId <string>: id of household
  - commentId <string>: id of comment to be deleted
  - signalType <string>: value of signal type
*/
export const deleteSignalComment = (householdId, commentId, signalType) => {
  return apiAction({
    path: `/household/${householdId}/signal/comment/${commentId}`,
    method: 'DELETE',
    passId: householdId,
    passSubId: signalType,
    onSuccess: getSignalComments,
    label: HouseholdTypes.DELETE_SIGNAL_COMMENT,
  });
};

/*
  API CALL TO SNOOZE SIGNAL
  - householdId <string>: id of household
  - data <object>: data needed to snooze signal
  - isSnooze <boolean>: if call is snooze or wake (delete)
*/
export const toggleSnoozeSignal = (householdId, data, isSnooze) => {
  return apiAction({
    path: `/household/${householdId}/signal/snooze`,
    method: isSnooze ? 'POST' : 'DELETE',
    data: data,
    passId: householdId,
    onSuccess: getHouseholdKFFs,
    label: HouseholdTypes.TOGGLE_SNOOZE_SIGNAL,
  });
};

/*
  ACTION TO SET TOGGLE DISPLAY OF SIGNAL DETAILS MODAL
  - showModal <boolean>: whether to show modal or not
  - signal <object>: signal details object needed for modal
*/
export const toggleShowSignalDetailsModal = (showModal, signal) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_SIGNAL_DETAILS_MODAL,
    payload: { showModal, signal },
  };
};

//ACTION TO SET HOUSEHOLD SURVEYS IN THE STORE <object>
export const setSurveyList = (surveys) => {
  return {
    type: HouseholdTypes.GET_SURVEYS,
    payload: surveys,
  };
};

/*
  API CALL TO GET HOUSEHOLD SURVEYS (PARENT)
  - id <string>: id of household
*/
export const getSurveyList = (id) => {
  return apiAction({
    path: `/household/${id}/surveys`,
    method: 'GET',
    onSuccess: setSurveyList,
    label: HouseholdTypes.GET_SURVEYS,
  });
};

//ACTION TO SET HOUSEHOLD SURVEY HISTORY IN THE STORE <object>
export const setSurveyHistory = (surveys) => {
  return {
    type: HouseholdTypes.GET_SURVEY_HISTORY,
    payload: surveys,
  };
};

/*
  API CALL TO GET HOUSEHOLD SURVEY HISTORY (Past Instances)
  - id <string>: id of household
  - data <object>: sort and filter params
*/
export const getSurveyHistory = (id, data) => {
  return apiAction({
    path: `/household/${id}/surveyinstances`,
    method: 'GET',
    data: data,
    onSuccess: setSurveyHistory,
    label: HouseholdTypes.GET_SURVEY_HISTORY,
  });
};

//ACTION TO SET SURVEY INSTANCE VALUE IN THE STORE <object>
export const setSurveyInstance = (surveyInstance) => {
  return {
    type: HouseholdTypes.CREATE_SURVEY_INSTANCE,
    payload: surveyInstance,
  };
};

/*
  API CALL TO GET A SURVEY INSTANCE
  - id <string>: id of survey to fetch
*/
export const getSurveyInstance = (id) => {
  return apiAction({
    path: `/survey/${id}`,
    method: 'GET',
    onSuccess: setSurveyInstance,
    label: HouseholdTypes.GET_SURVEY_INSTANCE,
  });
};

/*
  API CALL TO CREATE A NEW SURVEY INSTANCE
  - householdId <string>: id of household
  - surveyId <string>: id of parent survey to create instance of
*/
export const createSurveyInstance = (householdId, surveyId) => {
  return apiAction({
    path: `/household/${householdId}/survey/${surveyId}/create_instance`,
    method: 'POST',
    onSuccess: setSurveyInstance,
    label: HouseholdTypes.CREATE_SURVEY_INSTANCE,
  });
};

/*
  API CALL TO GET MOST RECENT SURVEY INSTANCE
  - householdId <string>: id of household
  - surveyId <string>: id of parent survey to get instance from
*/
export const getLatestSurveyInstance = (householdId, surveyId) => {
  return apiAction({
    path: `/household/${householdId}/survey/${surveyId}/get_latest`,
    onSuccess: setSurveyInstance,
    label: HouseholdTypes.GET_LATEST_SURVEY_INSTANCE,
  });
};

/*
  ACTION TO SET TOGGLE DISPLAY OF NEW TARGET MAP MODAL
  - showModal <boolean>: whether to show modal or not
  - survey <object>: survey details object needed for modal
*/
export const toggleShowSurveyResponsesModal = (showModal, survey) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_SURVEY_RESPONSES_MODAL,
    payload: { showModal, survey },
  };
};

//ACTION TO SET CSV FILE IN THE STORE <object>
export const setCSVFile = (csv) => {
  return {
    type: HouseholdTypes.DOWNLOAD_CSV_FILE,
    payload: csv,
  };
};

/*
  API CALL TO DOWNLOAD HOUSEHOLD CSV
  - id <string>: id of household
*/
export const downloadCSVFile = (id) => {
  return apiAction({
    path: `/household/${id}/loader/export`,
    method: 'GET',
    onSuccess: setCSVFile,
    label: HouseholdTypes.DOWNLOAD_CSV_FILE,
  });
};

//ACTION TO SET UPLOADED FILE IN THE STORE <object>
export const setUploadFile = (file) => {
  return {
    type: HouseholdTypes.SET_UPLOAD_FILE,
    payload: file,
  };
};

//ACTION TO SET CSV FILE IN THE STORE <object>
export const setUploadedCSVFile = (data) => {
  return {
    type: HouseholdTypes.UPLOAD_CSV_FILE,
    payload: data,
  };
};

/*
  API CALL TO UPLOAD HOUSEHOLD CSV FILE
  - id <string>: id of household
  - data <object>: data and file content to be uploaded
*/
export const uploadCSVFile = (id, data) => {
  return apiAction({
    path: `/household/${id}/loader/upload`,
    method: 'POST',
    data,
    onSuccess: setUploadedCSVFile,
    label: HouseholdTypes.UPLOAD_CSV_FILE,
  });
};

//ACTION TO CLEAR UPLOADED CSV RESULTS IN THE STORE
export const clearUploadCSVResults = () => {
  return {
    type: HouseholdTypes.CLEAR_UPLOAD_CSV_RESULTS,
  };
};

//ACTION TO LINKED INTEGRATION MEMBERS IN THE STORE <object>
export const setLinkedIntegrationMembers = (members, integration) => ({
  type: HouseholdTypes.GET_LINKED_INTEGRATION_MEMBERS,
  payload: { ...members, integration },
});

/*
  API CALL TO GET EXISTING LINKED MEMBERS TO HH INTEGRATION
  - id <string>: id of household
  - integration <string>: key of integration to query
*/
export const getLinkedIntegrationMembers = (id, integration) => {
  return apiAction({
    path: `/household/${id}/integration/${integration}`,
    method: 'GET',
    passValue: integration,
    onSuccess: setLinkedIntegrationMembers,
    label: HouseholdTypes.GET_LINKED_INTEGRATION_MEMBERS,
  });
};

//ACTION TO SET HOUSEHOLDS'S INTEGRATION SEARCH RESULTS IN STORE <object>
export const setHouseholdIntegrationSearchResults = (results) => ({
  type: HouseholdTypes.SEARCH_HOUSEHOLD_INTEGRATION,
  payload: results,
});

/*
  API CALL TO SEARCH HOUSEHOLD INTEGRATION FOR MEMBERS/ENTITIES
  - apiUrl <string>: API URL to search returned from integration
  - value <string>: search value to query against
*/
export const searchHouseholdIntegration = (apiUrl, value) => {
  return apiAction({
    path: `${url}${apiUrl}?q=${value}`,
    method: 'GET',
    absolute: true,
    onSuccess: setHouseholdIntegrationSearchResults,
    label: HouseholdTypes.SEARCH_HOUSEHOLD_INTEGRATION,
  });
};

/*
  API CALL TO SEARCH SALESFORCE INTEGRATION FOR MEMBERS/ENTITIES/HOUSEHOLD
  - apiUrl <string>: API URL to search returned from integration
  - searchType <string>: what type to search (member/entity/household)
  - data <object>: object with search values to query
*/
export const searchSalesforceIntegration = (apiUrl, searchType, data) => {
  let searchValue = '';
  if (searchType === 'member') {
    searchValue = `first=${data.firstName}&last=${data.lastName}`;
  }
  if (searchType === 'entity') {
    searchValue = `q=${data.searchValue}&mode=legalentity`;
  }
  if (searchType === 'household') {
    searchValue = `q=${data.searchValue}&mode=household`;
  }
  return apiAction({
    path: `${url}${apiUrl}?${searchValue}`,
    method: 'GET',
    absolute: true,
    onSuccess: setHouseholdIntegrationSearchResults,
    label: HouseholdTypes.SEARCH_HOUSEHOLD_INTEGRATION,
  });
};

/*
  API CALL TO LINK HOUSEHOLD MEMBER TO INTEGRATION
  - apiUrl <string>: API URL to search returned from integration
  - memberId <string>: id of household member to link if new create member
  - householdId <string>: household id to pass on success
  - integration <string>: key of integration to pass on success
*/
export const linkHouseholdMemberToIntegration = (
  apiUrl,
  memberId,
  householdId,
  integration
) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'POST',
    data: memberId !== 'new' ? { householdmember_hash: memberId } : null,
    absolute: true,
    passId: householdId,
    passSubId: integration,
    sendData: { newMember: memberId === 'new' },
    onSuccess: getLinkedIntegrationMembers,
    errorMessage: 'Linking Member Failed',
    successMessage: `Member Linked`,
    label: HouseholdTypes.LINK_HH_MEMBER_TO_INTEGRATION,
  });
};

/*
  API CALL TO CREATE SALESFORCE RECORD FROM HOUSEHOLD MEMBER/ENTITY
  - householdId <string>: id of household to create a record
  - type <string>: whether to create member or entity
  - memberId <string>: id of member to use to create record
*/
export const createSalesforceRecord = (householdId, type, memberId) => {
  return apiAction({
    path: `/household/${householdId}/integration/axasf/${type}/new`,
    method: 'POST',
    data: { householdmember_hash: memberId },
    passId: householdId,
    passSubId: 'axasf',
    onSuccess: getLinkedIntegrationMembers,
    successMessage: `Record Created`,
    label: HouseholdTypes.LINK_HH_MEMBER_TO_INTEGRATION,
  });
};

/*
  API CALL TO UNLINK A HOUSEHOLD MEMBER FROM INTEGRATION
  - apiUrl <string>: API URL to search returned from integration
  - householdId <string>: household id to pass on success
  - integration <string>: key of integration to pass on success
*/
export const unlinkHouseholdMemberFromIntegration = (
  apiUrl,
  householdId,
  integration
) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'PUT',
    absolute: true,
    passId: householdId,
    passSubId: integration,
    onSuccess: getLinkedIntegrationMembers,
    successMessage: `Member Unlinked`,
    label: HouseholdTypes.UNLINK_HH_MEMBER_FROM_INTEGRATION,
  });
};

/*
  API CALL TO UNLINK MEMBER/ENTITY FROM SALESFORCE INTEGRATION
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be unlinked
  - isEntity <boolean>: used to determine toast message
  - isPrimary <boolean>: used to determine toast message
*/
export const unlinkSalesforceMember = (
  householdId,
  memberId,
  isEntity = false,
  isPrimary = false,
  isIntegration = false
) => {
  return apiAction({
    path: `/household/${householdId}/integration/axasf/unlink_householdmember/${memberId}`,
    method: 'PUT',
    passId: householdId,
    passSubId: 'axasf',
    onSuccess: isIntegration ? getLinkedIntegrationMembers : getHouseholdInfo,
    successMessage: isEntity
      ? `Entity Unlinked`
      : isPrimary
        ? `Household Unlinked`
        : `Member Unlinked`,
    label: HouseholdTypes.UNLINK_SALESFORCE_MEMBER,
  });
};

/*
  API CALL TO SYNC HOUSEHOLD INTEGRATION MADE FROM THE MEMBER AND INSTRUMENT MODALS
  - apiUrl <string>: API URL to use returned from integration
  - id <string>: id of household to pass on success
*/
export const syncHouseholdModal = (apiUrl, id) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'PUT',
    passId: id,
    passSubId: 'sync',
    absolute: true,
    onSuccess: getHouseholdInfo,
    successMessage: 'Household Synced',
    errorMessage: 'Sync Failed',
    label: HouseholdTypes.SYNC_HOUSEHOLD_MODAL,
  });
};

/*
  API CALL TO SYNC HOUSEHOLD INTEGRATION (GET MOST UP TO DATE INFO)
  - apiUrl <string>: API URL to use returned from integration
  - id <string>: id of household to pass on success
  - integration <string>: key of integration to pass on success
*/
export const syncHouseholdIntegration = (apiUrl, id, integration) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'PUT',
    passId: id,
    passSubId: integration,
    absolute: true,
    onSuccess: getLinkedIntegrationMembers,
    successMessage: 'Household Synced',
    errorMessage: 'Sync Failed',
    label: HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATION,
  });
};

export const setSyncIntegrationsReport = (results) => ({
  type: HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATIONS,
  payload: results,
});

/*
 * AUTO AND BULK SYNCING, passing false on the first arg will sync all syncable integrations
 */
export const setBulkSync = (from = false, integrations = false) => ({
  type: HouseholdTypes.BULK_SYNC_HOUSEHOLD_INTEGRATIONS,
  payload: { runBulkSync: true, syncList: integrations, location: from },
});

export const unsetBulkSync = (from = false, integrations = false) => ({
  type: HouseholdTypes.BULK_SYNC_HOUSEHOLD_INTEGRATIONS,
  payload: { runBulkSync: false, syncList: integrations, location: from },
});
/*
  API CALL TO SYNC ALL HOUSEHOLD INTEGRATIONS
*/
export const syncHouseholdIntegrations = (
  householdId,
  integrations = false
) => {
  if (integrations && integrations?.length) {
    return apiAction({
      path: `/household/${householdId}/integrations/sync`,
      method: 'PUT',
      data: {
        sync_list: integrations,
      },
      onSuccess: setSyncIntegrationsReport,
      errorMessage: 'Bulk Sync Failed',
      label: HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATIONS,
    });
  }
  return apiAction({
    path: `/household/${householdId}/integrations/sync`,
    method: 'PUT',
    onSuccess: setSyncIntegrationsReport,
    errorMessage: 'Bulk Sync Failed',
    label: HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATIONS,
  });
};

/*
  API CALL TO DELAY AUTO SYNC OF INTEGRATIONS FOR A DAY
*/
export const delayAutoSyncIntegrations = (householdId) => {
  return apiAction({
    path: `/household/${householdId}/integrations/delay_sync`,
    method: 'POST',
    label: HouseholdTypes.DELAY_AUTO_SYNC_INTEGRATIONS,
  });
};

/*
  API CALL TO ALIGN SALESFORCE WITH ASSET-MAP (TWO WAY DATA SYNC)
  - id <string>: id of household
  - data <object>: data needed to sync household
*/
export const alignHouseholdSalesforce = (id, data) => {
  return apiAction({
    path: `/household/${id}/align`,
    method: 'POST',
    data: data,
    passId: id,
    passSubId: 'axasf',
    onSuccess: getLinkedIntegrationMembers,
    successMessage: 'Household Aligned',
    label: HouseholdTypes.ALIGN_HOUSEHOLD_SALESFORCE,
  });
};

//ACTION TO SET HOUSEHOLD FOLDER OPTIONS IN THE STORE <array>
export const setHouseholdFolderOptions = (folders) => {
  return {
    type: HouseholdTypes.GET_HOUSEHOLD_FOLDER_OPTIONS,
    payload: folders,
  };
};

/*
  API CALL TO GET HOUSEHOLD FOLDER OPTIONS
  - id <string>: id of household
  - integration <object>: id of file storage integration
*/
export const getHouseholdFolderOptions = (id, integration) => {
  return apiAction({
    path: `/household/${id}/integration/${integration}/available_folders`,
    method: 'GET',
    onSuccess: setHouseholdFolderOptions,
    label: HouseholdTypes.GET_HOUSEHOLD_FOLDER_OPTIONS,
  });
};

/*
  API CALL TO LINK HOUSEHOLD TO FILE STORAGE FOLDER
  - householdId <string>: id of household
  - integration <object>: id of file storage integration
  - folderId <string>: id of folder to link, if new create
*/
export const linkHouseholdFileStorage = (
  householdId,
  integration,
  folderId
) => {
  return apiAction({
    path: `/household/${householdId}/integration/${integration}/link`,
    method: 'PUT',
    data:
      folderId === 'new'
        ? { household: householdId, create: true }
        : { household: householdId, folder: folderId },
    passId: householdId,
    passSubId: integration,
    onSuccess: getHouseholdFolderOptions,
    successMessage: 'Household Linked',
    errorMessage: 'Error Enabling',
    label: HouseholdTypes.LINK_HOUSEHOLD_FILE_STORAGE,
  });
};

/*
  API CALL TO UNLINK HOUSEHOLD TO FILE STORAGE FOLDER
  - id <string>: id of household
  - integration <object>: id of file storage integration
*/
export const unlinkHouseholdFileStorage = (id, integration) => {
  return apiAction({
    path: `/household/${id}/integration/${integration}/unlink`,
    method: 'PUT',
    data: { household: id },
    passId: id,
    passSubId: integration,
    onSuccess: getHouseholdFolderOptions,
    successMessage: 'Household Unlinked',
    errorMessage: 'Error Unlinking',
    label: HouseholdTypes.UNLINK_HOUSEHOLD_FILE_STORAGE,
  });
};

//ACTION TO SET HOUSEHOLD GOOGLE DRIVE FILE STORAGE FOLDER IN THE STORE <object>
export const setHouseholdGoogleDriveFolder = (folder) => {
  return {
    type: HouseholdTypes.GET_HOUSEHOLD_FOLDER_GOOGLE,
    payload: folder,
  };
};

//ACTION TO SET HOUSEHOLD GOOGLE DRIVE FILE STORAGE FOLDER ERROR IN THE STORE <object>
export const setHouseholdGoogleDriveFolderFail = (error) => {
  return {
    type: HouseholdTypes.SET_HOUSEHOLD_FOLDER_GOOGLE_FAIL,
    payload: error,
  };
};

//ACTION TO SET HOUSEHOLD DROPBOX FILE STORAGE FOLDER IN THE STORE <object>
export const setHouseholdDropboxFolder = (folder) => {
  return {
    type: HouseholdTypes.GET_HOUSEHOLD_FOLDER_DROPBOX,
    payload: folder,
  };
};

//ACTION TO SET HOUSEHOLD DROPBOX FILE STORAGE FOLDER ERROR IN THE STORE <object>
export const setHouseholdDropboxFolderFail = (error) => {
  return {
    type: HouseholdTypes.SET_HOUSEHOLD_FOLDER_DROPBOX_FAIL,
    payload: error,
  };
};

//ACTION TO SET HOUSEHOLD BOX FILE STORAGE FOLDER IN THE STORE <object>
export const setHouseholdBoxFolder = (folder) => {
  return {
    type: HouseholdTypes.GET_HOUSEHOLD_FOLDER_BOX,
    payload: folder,
  };
};

//ACTION TO SET HOUSEHOLD BOX FILE STORAGE FOLDER ERROR IN THE STORE <object>
export const setHouseholdBoxFolderFail = (error) => {
  return {
    type: HouseholdTypes.SET_HOUSEHOLD_FOLDER_BOX_FAIL,
    payload: error,
  };
};

/*
  API CALL TO GET HOUSEHOLD FOLDER OPTIONS
  - householdId <string>: id of household
  - integration <object>: id of file storage integration
  - folderId <string>: id of folder to fetch, if blank gets default
*/
export const getHouseholdIntegrationFolder = (
  householdId,
  integration,
  folderId = null
) => {
  return apiAction({
    path: `/household/${householdId}/integration/${integration}/load_folder${
      folderId ? `?folder=${folderId}` : ''
    }`,
    method: 'GET',
    onSuccess:
      integration === 'googledrive'
        ? setHouseholdGoogleDriveFolder
        : integration === 'dropbox'
          ? setHouseholdDropboxFolder
          : integration === 'box'
            ? setHouseholdBoxFolder
            : null,
    onFailure:
      integration === 'googledrive'
        ? setHouseholdGoogleDriveFolderFail
        : integration === 'dropbox'
          ? setHouseholdDropboxFolderFail
          : integration === 'box'
            ? setHouseholdBoxFolderFail
            : null,
    label: HouseholdTypes.GET_HOUSEHOLD_FOLDER,
  });
};

/*
  ACTION TO TOGGLE DISPLAY OF UPLOAD FILE MODAL
  - isShowing <boolean>: whether modal is showing or not
  - integration <string>: name of integration for display in modal
  - folderId <string>: id of folder to upload files to
  - type <string>: location of upload modal (household/member/instrument)
*/
export const toggleShowUploadFileModal = (
  isShowing,
  integration,
  folderId,
  type
) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_UPLOAD_FILE_MODAL,
    payload: { isShowing, integration, folderId, type },
  };
};

//ACTION TO SET UPLOADED FILE ID IN THE STORE <string>
export const setFileStorageUploadFile = (fileId) => {
  return {
    type: HouseholdTypes.UPLOAD_FILE_STORAGE_FILE,
    payload: fileId,
  };
};

/*
  API CALL TO SET FAILED FILE UPLOAD IN THE STORE
  - fileId <string>: id of file that failed to upload
  - data <object>: API response to failure (error message)
*/
export const setFileStorageUploadFailed = (fileId, data) => {
  return {
    type: HouseholdTypes.SET_FILE_STORAGE_UPLOAD_FAILED,
    payload: { fileId: fileId, message: data.data.message },
  };
};

/*
  API CALL TO UPLOAD FILE TO FILE STORAGE INTEGRATION
  - integration <string>: id of integration to be uploaded to
  - folderId <string>: id of folder to upload files to
  - data <object>: file content to be uploaded
  - fileId <string>: locally generated id to track status of upload
*/
export const uploadFileStorageFile = (integration, folderId, data, fileId) => {
  return apiAction({
    path: `/member/integration/${integration}/upload/${folderId}`,
    method: 'PUT',
    data: data,
    passId: fileId,
    onSuccess: setFileStorageUploadFile,
    onFailure: setFileStorageUploadFailed,
    label: HouseholdTypes.UPLOAD_FILE_STORAGE_FILE,
  });
};

/*
  ACTION TO SET DESIRED INTEGRATION TO REFRESH IN THE STORE
  - userOpened <boolean>: ignored, used for other options
  - integration <string>: id of integration to be refreshed
*/
export const fetchUpdatedFileStorage = (userOpened, integration) => {
  return {
    type: HouseholdTypes.REFRESH_FILE_STORAGE,
    payload: integration,
  };
};

//
const setUpdatedHouseholdAdvisor = () => {
  return {
    type: HouseholdTypes.UPDATE_HOUSEHOLD_ADVISOR,
  };
};

/*
  API CALL TO UPDATE LEAD ADVISOR
  - household_id <string>: id of household to update
  - advisor_id <string>: id of advisor to assign to
*/
export const updateHouseholdAdvisor = (household_id, advisor_id) => {
  return apiAction({
    path: `/household/${household_id}/advisor_assign/${advisor_id}`,
    method: 'PUT',
    onSuccess: setUpdatedHouseholdAdvisor,
    successMessage: 'Household Reassigned',
    errorMessage: 'Advisor Update Failed',
    label: HouseholdTypes.UPDATE_HOUSEHOLD_ADVISOR,
  });
};

//ACTION TO SET HOUSEHOLD MODAL STATE
export const setModalState = (modalState) => ({
  type: HouseholdTypes.SET_MODAL_STATE,
  payload: modalState,
});

// HOUSEHOLD LEGAL
/*
  ACTION TO SET TOGGLE DISPLAY OF LEGAL MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing insurance
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowLegalModal = (
  showModal,
  newLegal = false,
  listLegals = false
) => {
  return {
    type: HouseholdTypes.TOGGLE_SHOW_LEGAL_MODAL,
    payload: {
      showModal: showModal,
      newLegal: newLegal,
      listLegals: listLegals,
    },
  };
};

export const setLegals = (legals) => ({
  type: HouseholdTypes.GET_LEGALS,
  payload: legals,
});

export const getLegals = (householdId) => {
  return apiAction({
    path: `/household/${householdId}/legal`,
    method: 'GET',
    label: HouseholdTypes.GET_LEGALS,
    onSuccess: setLegals,
  });
};

export const createLegal = (householdId, legal, type) => {
  return apiAction({
    path: `/household/${householdId}/legal`,
    method: 'POST',
    data: legal,
    passId: householdId,
    successMessage: `Created ${type}`,
    onSuccess: getHouseholdInfo,
    label: HouseholdTypes.CREATE_LEGAL,
  });
};

/*
  API CALL TO CREATE PROPOSAL LEGAL
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - legal obj
*/
export const createProposalLegal = (householdId, proposalId, legal, type) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'add',
      object_type: 'legal',
      object_id: null,
      diff_data: legal,
    },
    passId: householdId,
    passSubId: proposalId,
    successMessage: `Created ${type}`,
    onSuccess: getProposalHouseholdInfo,
    label: HouseholdTypes.CREATE_PROPOSAL_LEGAL,
  });
};

export const setLegal = (legal) => ({
  type: HouseholdTypes.GET_LEGAL,
  payload: legal,
});

export const getLegal = (householdId, legalId) => {
  return apiAction({
    path: `/household/${householdId}/legal/${legalId}`,
    method: 'GET',
    label: HouseholdTypes.GET_LEGAL,
    onSuccess: setLegal,
  });
};

export const updateLegal = (householdId, legalId, legal, type) => {
  return apiAction({
    path: `/household/${householdId}/legal/${legalId}`,
    method: 'PUT',
    data: legal,
    passId: householdId,
    successMessage: `${type} Updated`,
    onSuccess: getHouseholdInfo,
    label: HouseholdTypes.UPDATE_LEGAL,
  });
};

/*
  API CALL TO UPDATE PROPOSAL LEGAL
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - legal <object>: data needed to update legal
  - legalId <string>: uuid of legal proposal is for (only for modifying existing legal)
  - type <string>: legal type
  - proposalActionId <string>: uuid of proposal action (only for modifying an existing proposal action)
*/
export const updateProposalLegal = (
  householdId,
  proposalId,
  legal,
  legalId,
  type = 'Legal',
  proposalActionId = false
) => {
  const idValue = proposalActionId
    ? { proposal_action_id: proposalActionId }
    : { object_id: legalId };
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'modify',
      object_type: 'legal',
      diff_data: legal,
      ...idValue,
    },
    passId: householdId,
    passSubId: proposalId,
    successMessage: `${type} Updated`,
    onSuccess: getProposalHouseholdInfo,
    label: HouseholdTypes.UPDATE_PROPOSAL_LEGAL,
  });
};

export const deleteLegal = (householdId, legalId) => {
  return apiAction({
    path: `/household/${householdId}/legal/${legalId}`,
    method: 'DELETE',
    passId: householdId,
    passSubId: legalId,
    successMessage: `Legal Deleted`,
    onSuccess: getHouseholdInfo,
    label: HouseholdTypes.DELETE_LEGAL,
  });
};

/*
  API CALL TO DELETE LEGAL IN PROPOSAL MODE
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - legalId <string>: id of legal to be deleted
  - type <string>: legal type
*/
export const deleteProposalLegal = (
  householdId,
  proposalId,
  legalId,
  type = 'Legal'
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'delete',
      object_type: 'legal',
      object_id: legalId,
    },
    successMessage: `${type} Deleted`,
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    label: HouseholdTypes.DELETE_PROPOSAL_LEGAL,
  });
};

export const clearLegal = () => ({
  type: HouseholdTypes.CLEAR_LEGAL,
});

export const setMembersLegals = (legals) => ({
  type: HouseholdTypes.GET_MEMBERS_LEGALS,
  payload: legals,
});

/*
  API CALL TO DELETE PROPOSAL ACTION IN PROPOSAL MODE
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - proposalActionId <string>: uuid of proposalAction to be deleted
  - type <string>: legal type
*/
export const deleteProposalActionLegal = (
  householdId,
  proposalId,
  proposalActionId,
  type = 'Legal'
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action/${proposalActionId}`,
    method: 'DELETE',
    successMessage: `${type} Deleted`,
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    label: HouseholdTypes.DELETE_PROPOSAL_LEGAL,
  });
};

//ACTION TO SET FAMILY MAPS IN THE STORE <array>
export const setFamilyMaps = (familyMaps) => ({
  type: HouseholdTypes.GET_FAMILY_MAPS,
  payload: familyMaps,
});

/*
  API CALL TO GET FAMILY MAPS
  - household_id <string>: id of household
*/
export const getFamilyMaps = (householdId) => {
  return apiAction({
    path: `/household/${householdId}/family_map`,
    method: 'GET',
    label: HouseholdTypes.GET_FAMILY_MAPS,
    onSuccess: setFamilyMaps,
  });
};

//ACTION TO SET FAMILY MAP IN THE STORE <object>
export const setFamilyMap = (familyMap) => ({
  type: HouseholdTypes.GET_FAMILY_MAP,
  payload: familyMap,
});

/*
  API CALL TO GET FAMILY MAP
  - household_id <string>: id of household to family maps for
    - familyMapId <string>: id of family map to get
*/
export const getFamilyMap = (householdId, familyMapId) => {
  return apiAction({
    path: `/household/${householdId}/family_map/${familyMapId}`,
    method: 'GET',
    label: HouseholdTypes.GET_FAMILY_MAP,
    onSuccess: setFamilyMap,
  });
};

/*
  API CALL TO CREATE FAMILY MAP
  - household_id <string>: id of household
  - familyMap <object>: family map data
*/
export const createFamilyMap = (householdId, familyMap) => {
  return apiAction({
    path: `/household/${householdId}/family_map`,
    method: 'POST',
    data: familyMap,
    successMessage: `Created Family Map`,
    onSuccess: setFamilyMap,
    label: HouseholdTypes.CREATE_FAMILY_MAP,
  });
};

/*
  API CALL TO UPDATE FAMILY MAP
  - household_id <string>: id of household
  - familyMap <object>: family map data to update
*/
export const updateFamilyMap = (householdId, familyMap) => {
  return apiAction({
    path: `/household/${householdId}/family_map/${familyMap?.id}`,
    method: 'PUT',
    data: familyMap,
    passId: householdId,
    passSubId: familyMap?.id,
    // successMessage: `Updated Family Map`,
    onSuccess: getFamilyMap,
    label: HouseholdTypes.UPDATE_FAMILY_MAP,
  });
};

/*
  API CALL TO DELETE FAMILY MAP
  - household_id <string>: id of household
  - familyMapId <string>: id of family map to delete
*/
export const deleteFamilyMap = (householdId, familyMapId) => {
  return apiAction({
    path: `/household/${householdId}/family_map/${familyMapId}`,
    method: 'DELETE',
    passId: householdId,
    successMessage: 'Family Map Deleted',
    onSuccess: getFamilyMaps,
    label: HouseholdTypes.DELETE_FAMILY_MAP,
  });
};
