/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { SideNavItem } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { useLocation, useParams } from 'react-router-dom';
import {
  maxDevice,
  heights,
  colors,
  boxShadows,
  fonts,
} from '../styles/variables';
import {
  doubleArrowWhite,
  plusCircleDark,
  lightBlueCirclePlus,
  pinkCirclePlus,
  orangeCirclePlus,
  blueCirclePlus,
  pdf,
  layouts,
  addStencil,
  removeStencil,
  stencils,
  toggle,
  userAdd,
  addEntity,
  proposals,
  sync,
  link,
  professionalAdd,
} from '../assets';
import {
  toggleShowMemberModal,
  toggleShowEntityModal,
  toggleShowAdvisorModal,
  toggleShowCashFlowModal,
  toggleShowAssetModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  toggleShowNewTargetMapModal,
  toggleShowHiddenFinancials,
  toggleShowAssetMapLayoutsModal,
  toggleShowStencilModal,
  clearStencil,
  toggleShowAmrMembers,
  toggleShowAmrLegend,
  toggleShowAmrHidden,
  toggleShowAmrCustodian,
  toggleShowAmrEntity,
  // toggleShowAmrKffs,
  toggleShowAmrPropCas,
  toggleShowAmrMAW,
  toggleShowAmrRiskalyze,
  createAssetMapReport,
  toggleShowSignalsPanel,
  toggleExpandSideNav,
  toggleShowCashValueColumn,
  toggleShowEntityRollup,
  toggleShowProposalsModal,
  syncHouseholdSummary,
  setBulkSync,
  clearInstrumentInfo,
  clearMemberInfo,
  toggleShowProfessionalModal,
} from '../store/actions';
import {
  UseOutsideClick,
  sideNavItems,
  ErrorNotification,
  integrationOptions,
  InfoNotification,
  SendTrackingEvent,
} from '../utils';
import PropTypes from 'prop-types';
import {
  GenerateMenuItemContent,
  MenuItem,
  SubMenuContainer,
  MenuHeading,
  MenuContent,
} from '../components/ContextMenu';

const SideNavContainer = ({ isConsumer = false }) => {
  const ref = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const {
    user,
    loadedUser,
    hasBranding,
    advisorLogo,
    currentHousehold,
    householdAdvisorConfigs,
    householdConfigs,
    showTargetMaps,
    showHouseholdSignals,
    showSignalsPanel,
    sideNavExpanded,
    showSurveys,
    sideNavWidth,
    isStencil,
    hasEntityRollup,
    showEntityRollup,
    hasProposals,
    showProposalMode,
    invocationId,
  } = useSelector((state) => ({
    user: state.user,
    loadedUser: state.user.loadedUser,
    hasBranding: state.user.hasBranding,
    householdAdvisorConfigs: state.configs?.allFields,
    advisorLogo: state.households.advisorLogo,
    currentHousehold: state.households.currentHousehold,
    householdConfigs: state.households.householdConfigs,
    showTargetMaps: state.user.showTargetMaps,
    showHouseholdSignals: state.user.showHouseholdSignals,
    showSignalsPanel: state.user.showSignalsPanel,
    showSurveys: state.user.showSurveys,
    sideNavExpanded: state.user.sideNavExpanded,
    sideNavWidth: state.user.sideNavWidth,
    isStencil: state.households.isStencil,
    hasEntityRollup: state.user.hasEntityRollup,
    showEntityRollup: state.user?.user?.preferences?.amr?.show_entity_rollup,
    hasProposals: state.user.hasProposals,
    showProposalMode: state.households.showProposalMode,
    invocationId: state.configs.invocationId,
  }));
  const [navOptions, setNavOptions] = useState([]);
  const [loadedSideNav, setLoadedSideNav] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showPropCas, setShowPropCas] = useState(false);
  const [showRiskalyze, setShowRiskalyze] = useState(true);
  const [showMAW, setShowMAW] = useState(true);
  const [riskalyzeEnabled, setRiskalyzeEnabled] = useState(true);
  const [mawEnabled, setMAWEnabled] = useState(true);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  //Branding height is 0 unless custom branding is enabled
  const [hasCustomBranding, setHasCustomBranding] = useState(false);
  const [brandingHeight, setBrandingHeight] = useState('0px');

  //Need to determine if sub menu inverts up
  const invertYMenu = () => {
    const pageHeight = window.innerHeight;
    if (pageHeight <= 745) {
      return true;
    }
    return false;
  };

  const isAssetMapRoute = () => {
    const pathName = window.location.pathname;
    const splitName = pathName.split('/');
    return splitName.includes('assetmap');
  };

  const isFinancialsRoute = () => {
    const pathName = window.location.pathname;
    const splitName = pathName.split('/');
    return splitName.includes('financials');
  };

  const isSummaryRoute = () => {
    const pathName = window.location.pathname;
    const splitName = pathName.split('/');
    return splitName.includes('summary') && showHouseholdSignals;
  };

  const checkCanToggleEntityDisplay = () => {
    if (hasEntityRollup && showEntityRollup) {
      if (user.amrShowEntity) {
        return ErrorNotification(
          'Cannot hide entities when showing Entity Ownership'
        );
      }
    }
    return dispatch(toggleShowAmrEntity(user.amrShowEntity));
  };

  const toggleRollupDisplay = () => {
    if (!showEntityRollup) {
      dispatch(toggleShowAmrEntity(false));
    }
    return dispatch(toggleShowEntityRollup(showEntityRollup));
  };

  const isIntegrationsVisible = (integrations) => {
    if (!isAssetMapRoute()) {
      return false;
    }

    if (!integrations.available) {
      return false;
    }

    let integrationMap = {};
    integrationOptions.forEach((integration) => {
      integrationMap[integration.id] = {
        isFileStorage: integration?.file_storage,
      };
    });

    let isVisible = false;
    for (const [partner, details] of Object.entries(integrations.partners)) {
      if (integrationMap[partner].isFileStorage) {
        continue;
      }
      if (!details.enabled) {
        continue;
      }
      if (details.sync.available) {
        isVisible = true;
      }
    }
    return isVisible;
  };

  const addIntegrationMenu = () => {
    let kids = [];

    for (const [partner, details] of Object.entries(
      currentHousehold?.integrations?.partners
    )) {
      if (!details.enabled || !details?.sync?.available) {
        continue;
      }

      let partnerLabel = '';
      integrationOptions.forEach((integration) => {
        if (integration.id === partner) {
          partnerLabel = integration.name;
        }
      });

      kids.push({
        label: `Sync ${partnerLabel}`,
        image: sync,
        name: `sync-${partner}`,
        isVisible: true,
        onClick: () => syncIntegration(partner, partnerLabel),
      });
    }

    if (kids.length) {
      kids.unshift({
        label: `Sync All`,
        image: sync,
        isVisible: true,
        name: `sync-all`,
        onClick: () => syncAll(),
      });
    }

    if (kids.length) {
      actionItemsMenu.forEach((menuItem) => {
        if (menuItem.label === 'Integrations' && !menuItem.children.length) {
          menuItem.isVisible = true;
          menuItem.children = [...kids];
        }
      });
      setActionItems(actionItemsMenu.filter((item) => item?.isVisible));
    }
  };

  const syncIntegration = (integration, label) => {
    InfoNotification(`Syncing ${label}`);
    SendTrackingEvent(
      invocationId,
      'action',
      'integration_sync',
      'household_amr',
      {
        household: householdId,
        integration: integration,
      }
    );
    return closeMenu(dispatch(syncHouseholdSummary(householdId, integration)));
  };

  const syncAll = () => {
    return closeMenu(dispatch(setBulkSync('household_amr')));
  };

  const actionItemsMenu = [
    {
      label: 'Generate PDF',
      image: pdf,
      name: 'generate-pdf',
      isVisible: isAssetMapRoute(),
      onClick: () => closeMenu(dispatch(createAssetMapReport(true))),
    },
    {
      label: 'Member',
      image: userAdd,
      name: 'add-member',
      isVisible: true,
      onClick: () => {
        dispatch(clearMemberInfo());
        closeMenu(dispatch(toggleShowMemberModal(true)));
      },
    },
    {
      label: 'Professional',
      image: professionalAdd,
      name: 'add-professional',
      isVisible: true,
      onClick: () => {
        closeMenu(dispatch(toggleShowProfessionalModal(true)));
      },
    },
    {
      label: 'Entity',
      image: addEntity,
      name: 'add-entity',
      isVisible: true,
      onClick: () => {
        dispatch(clearMemberInfo());
        closeMenu(dispatch(toggleShowEntityModal(true)));
      },
    },
    {
      label: 'Cash Flow',
      image: lightBlueCirclePlus,
      name: 'add-cash-flow',
      isVisible: true,
      onClick: () => {
        dispatch(clearInstrumentInfo());
        closeMenu(dispatch(toggleShowCashFlowModal(true, true)));
      },
    },
    {
      label: 'Asset',
      image: pinkCirclePlus,
      name: 'add-asset',
      isVisible: true,
      onClick: () => {
        dispatch(clearInstrumentInfo());
        closeMenu(dispatch(toggleShowAssetModal(true, true)));
      },
    },
    {
      label: 'Liability',
      image: orangeCirclePlus,
      name: 'add-liability',
      isVisible: true,
      onClick: () => {
        dispatch(clearInstrumentInfo());
        closeMenu(dispatch(toggleShowLiabilityModal(true, true)));
      },
    },
    {
      label: 'Insurance',
      image: blueCirclePlus,
      name: 'add-insurance',
      isVisible: true,
      onClick: () => {
        dispatch(clearInstrumentInfo());
        closeMenu(dispatch(toggleShowInsuranceModal(true, true)));
      },
    },
    {
      label: 'Target-Map',
      image: plusCircleDark,
      name: 'add-tm',
      isVisible: showTargetMaps && !isStencil,
      onClick: () => closeMenu(dispatch(toggleShowNewTargetMapModal(true))),
    },
    {
      label: 'Advisor',
      image: plusCircleDark,
      name: 'add-advisor',
      isVisible: true,
      onClick: () => closeMenu(dispatch(toggleShowAdvisorModal(true))),
    },
    {
      label: 'Signals',
      hasToggle: true,
      name: 'show-signals',
      checked: showSignalsPanel,
      isVisible: isSummaryRoute(),
      onClick: () => dispatch(toggleShowSignalsPanel(showSignalsPanel)),
    },
    {
      label: 'Hidden',
      hasToggle: true,
      name: 'show-hidden',
      checked: user.showHiddenFinancials,
      isVisible: isFinancialsRoute(),
      onClick: () =>
        dispatch(toggleShowHiddenFinancials(user.showHiddenFinancials)),
    },
    {
      label: 'Cash Value',
      hasToggle: true,
      name: 'show-cash-value',
      checked: user.showCashValueColumn,
      isVisible: isFinancialsRoute(),
      onClick: () =>
        dispatch(toggleShowCashValueColumn(user.showCashValueColumn)),
    },
    {
      label: 'Layouts',
      name: 'show-layouts',
      image: layouts,
      isVisible: isAssetMapRoute(),
      onClick: () => closeMenu(dispatch(toggleShowAssetMapLayoutsModal(true))),
    },
    {
      label: 'Stencils',
      image: stencils,
      name: 'stencils-parent',
      isVisible: isAssetMapRoute(),
      children: [
        {
          label: 'Apply Stencil',
          name: 'apply-stencil',
          image: addStencil,
          isVisible: true,
          onClick: () => closeMenu(dispatch(toggleShowStencilModal(true))),
        },
        {
          label: 'Clear Stencil',
          name: 'clear-stencil',
          image: removeStencil,
          isVisible: true,
          onClick: () => closeMenu(dispatch(clearStencil(householdId))),
        },
      ],
    },
    {
      label: 'Drafts',
      name: 'show-proposals',
      image: proposals,
      isVisible: isAssetMapRoute() && hasProposals,
      onClick: () => closeMenu(dispatch(toggleShowProposalsModal(true))),
    },
    {
      label: 'Toggle Visibility',
      image: toggle,
      name: 'toggle-parent',
      isVisible: isAssetMapRoute(),
      children: [
        {
          label: 'Members',
          hasToggle: true,
          name: 'show-members',
          checked: user.amrShowMembers,
          isVisible: true,
          onClick: () => dispatch(toggleShowAmrMembers(user.amrShowMembers)),
        },
        {
          label: 'Legend',
          hasToggle: true,
          name: 'show-legend',
          checked: user.amrShowLegend,
          isVisible: true,
          onClick: () => dispatch(toggleShowAmrLegend(user.amrShowLegend)),
        },
        {
          label: 'Hidden',
          hasToggle: true,
          name: 'show-hidden',
          checked: user.amrShowHidden,
          isVisible: true,
          onClick: () => dispatch(toggleShowAmrHidden(user.amrShowHidden)),
        },
        // {
        //   label: 'Show KFFs',
        //   hasToggle: true,
        //   checked: user.amrShowKffs,
        //   isVisible: true,
        //   onClick: () => dispatch(toggleShowAmrKffs(user.amrShowKffs)),
        // },
        {
          label: 'Entity',
          hasToggle: true,
          name: 'show-entity',
          checked: user.amrShowEntity,
          isVisible: true,
          onClick: checkCanToggleEntityDisplay,
        },
        {
          label: 'Entity Ownership',
          hasToggle: true,
          name: 'show-rollup',
          checked: showEntityRollup,
          isVisible: hasEntityRollup,
          onClick: toggleRollupDisplay,
        },
        {
          label: 'Custodian',
          hasToggle: true,
          name: 'show-custodian',
          checked: user.amrShowCustodian,
          isVisible: true,
          onClick: () =>
            dispatch(toggleShowAmrCustodian(user.amrShowCustodian)),
        },
        {
          label: 'P&C',
          hasToggle: true,
          name: 'show-propcas',
          checked: user.user?.preferences?.amr?.display_property_casualty,
          isVisible: showPropCas,
          onClick: () =>
            dispatch(
              toggleShowAmrPropCas(
                user.user.preferences.amr.display_property_casualty
              )
            ),
        },
        {
          label: 'Nitrogen',
          hasToggle: true,
          checked: riskalyzeEnabled,
          isVisible: showRiskalyze,
          name: 'show-riskalyze',
          onClick: () =>
            dispatch(
              toggleShowAmrRiskalyze(user.user.preferences.amr.signpost)
            ),
        },
        {
          label: 'Morningstar AWS',
          hasToggle: true,
          checked: mawEnabled,
          isVisible: showMAW,
          name: 'show-msaws',
          onClick: () =>
            dispatch(toggleShowAmrMAW(user.user.preferences.amr.signpost)),
        },
      ],
    },
    {
      label: 'Integrations',
      image: link,
      name: 'integrations',
      isVisible: showIntegrations,
      children: [],
    },
  ];

  //If user has custom branding set height depending on if expanded
  useEffect(() => {
    if (loadedUser) {
      if (householdAdvisorConfigs?.branding?.side_nav) {
        setHasCustomBranding(true);
        setBrandingHeight(sideNavExpanded ? '60px' : '100px');
      } else {
        setBrandingHeight('0px');
        setHasCustomBranding(false);
      }
    }
  }, [sideNavExpanded, loadedUser, hasBranding, advisorLogo]);

  const householdHasIntegration = (integration) => {
    return (
      currentHousehold?.integrations?.available === true &&
      currentHousehold?.integrations?.partners?.[integration]?.enabled === true
    );
  };

  useEffect(() => {
    if (user) {
      let updatedItems = [...actionItemsMenu];
      setShowPropCas(user.hasPropCas ? true : false);
      const hasRiskalyze = householdHasIntegration('riskalyze');
      setShowRiskalyze(hasRiskalyze);
      const hasMAW = householdHasIntegration('msaws');
      setShowMAW(hasMAW);
      if (user.user && user.user.hasOwnProperty('preferences')) {
        if (user.user.preferences.hasOwnProperty('amr')) {
          const isRiskalyzeEnabled =
            user.user.preferences.amr?.signpost === 'riskalyze' ||
            (Array.isArray(user.user.preferences.amr?.signpost) &&
              user.user.preferences.amr?.signpost.includes('riskalyze'));
          const isMAWEnabled =
            user.user.preferences.amr?.signpost === 'msaws' ||
            (Array.isArray(user.user.preferences.amr?.signpost) &&
              user.user.preferences.amr?.signpost.includes('msaws'));
          updatedItems = actionItemsMenu.map((item) => {
            if (item.name === 'toggle-parent') {
              item.children = item.children.map((child) => {
                if (child.name === 'show-riskalyze') {
                  child.checked = isRiskalyzeEnabled;
                  child.isVisible = hasRiskalyze;
                }
                if (child.name === 'show-msaws') {
                  child.checked = isMAWEnabled;
                  child.isVisible = hasMAW;
                }
                return child;
              });
              return item;
            }
            return item;
          });
          setRiskalyzeEnabled(isRiskalyzeEnabled);
          setMAWEnabled(isMAWEnabled);
        }
      }
      if (currentHousehold?.integrations) {
        if (isIntegrationsVisible(currentHousehold?.integrations)) {
          setShowIntegrations(true);
          return addIntegrationMenu();
        } else {
          setShowIntegrations(false);
        }
      }
      setActionItems(updatedItems.filter((item) => item?.isVisible));
    }
  }, [user, currentHousehold, location]);

  useEffect(() => {
    if (isConsumer) {
      let consumerNav = sideNavItems.filter((item) => item.showConsumer);
      consumerNav = consumerNav.slice(0, 4);
      setNavOptions(consumerNav);
    } else {
      setNavOptions(sideNavItems.filter((item) => !item.hidden));
    }
  }, [isConsumer]);

  useEffect(() => {
    if (isConsumer && householdConfigs?.show) {
      const allowedRoutes = householdConfigs?.show;
      const consumerNav = sideNavItems.filter((item) => item.showConsumer);
      let updatedNavItems = [...consumerNav];
      updatedNavItems = updatedNavItems.map((item) => {
        if (!item.alwaysShow) {
          item.hidden = !allowedRoutes.includes(item.key);
        }
        return item;
      });
      updatedNavItems = updatedNavItems.filter((item) => !item.hidden);
      setNavOptions(updatedNavItems.sort((a, b) => a.order - b.order));
      setLoadedSideNav(true);
    }
  }, [householdConfigs?.show, isConsumer]);

  useEffect(() => {
    if (loadedUser !== null && !isConsumer) {
      let updatedNavItems = [...sideNavItems];
      updatedNavItems = updatedNavItems.map((item) => {
        if (item.key === 'signals') {
          item.hidden = !showHouseholdSignals;
        }
        if (item.key === 'questionnaires') {
          item.hidden = !showSurveys;
        }
        return item;
      });
      updatedNavItems = sideNavItems.filter((item) => !item.hidden);
      setNavOptions(updatedNavItems.sort((a, b) => a.order - b.order));
      setLoadedSideNav(true);
    }
  }, [loadedUser, showHouseholdSignals, showSurveys]);

  const closeMenu = (fun) => {
    setShowActionMenu(false);
    return fun;
  };

  UseOutsideClick(ref, () => {
    if (showActionMenu) {
      setShowActionMenu(!showActionMenu);
    }
  });

  return (
    <Parent>
      <SideNavMenu style={{ width: sideNavWidth }}>
        <SideNavHeader isConsumer={isConsumer}>
          <MenuButton
            onClick={() => dispatch(toggleExpandSideNav(sideNavExpanded))}
          >
            {sideNavExpanded ? (
              <>
                <MenuIconContainer>
                  <MenuIcon
                    src={doubleArrowWhite}
                    alt="back"
                    data-image="collapse-side-nav"
                  />
                </MenuIconContainer>
                <MenuText>Menu</MenuText>
              </>
            ) : (
              <MenuIconContainer>
                <MenuIconExpand
                  src={doubleArrowWhite}
                  alt="expand"
                  data-image="expand-side-nav"
                />
              </MenuIconContainer>
            )}
          </MenuButton>
        </SideNavHeader>
        <SideNavContents
          isConsumer={isConsumer}
          brandingHeight={brandingHeight}
        >
          {navOptions.map((item, index) => {
            if (item.label === 'Actions') {
              return (
                <SideNavItem
                  key={index}
                  item={item}
                  isExpanded={sideNavExpanded}
                  navWidth={sideNavWidth}
                  onClick={() =>
                    !showProposalMode
                      ? setShowActionMenu(!showActionMenu)
                      : ErrorNotification('Cannot use in draft mode')
                  }
                />
              );
            } else {
              return (
                <SideNavItem
                  key={index}
                  item={item}
                  isExpanded={sideNavExpanded}
                  navWidth={sideNavWidth}
                  isLoading={!loadedSideNav}
                />
              );
            }
          })}
        </SideNavContents>
        {hasCustomBranding && (
          <AssetMapBranding
            isExpanded={sideNavExpanded}
            sideNavWidth={sideNavWidth}
          >
            <p>Powered By</p>
            <img
              src={householdAdvisorConfigs.branding.side_nav}
              alt="Asset-Map"
            />
          </AssetMapBranding>
        )}
        {/* <BottomNavItemContainer style={{ width: navWidth }}>
          <BottomLinkContainer to={sideNavBottomLink.to}>
            <NavIcon src={sideNavBottomLink.icon} />
            {navExpanded ? (
              <NavLabel>
                <ItemLabel>{sideNavBottomLink.label}</ItemLabel>
              </NavLabel>
            ) : null}
          </BottomLinkContainer>
        </BottomNavItemContainer> */}
      </SideNavMenu>
      {showActionMenu ? (
        <FlyOutMenu
          ref={ref}
          style={sideNavExpanded ? { left: '221px' } : { left: '59px' }}
        >
          <MenuHeading>Actions</MenuHeading>
          <MenuContent>
            {actionItems.map((menuItem, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={menuItem.onClick}
                  data-text={menuItem.name}
                >
                  {GenerateMenuItemContent(
                    menuItem,
                    index === actionItems.length - 1
                  )}
                  {menuItem.children && (
                    <SubMenuContainer
                      invertX={false}
                      invertY={invertYMenu()}
                      width={147}
                    >
                      {menuItem.children.map((childItem, index) => {
                        return (
                          childItem.isVisible && (
                            <MenuItem
                              key={index}
                              onClick={childItem.onClick}
                              data-text={childItem.name}
                            >
                              {childItem.tooltip && (
                                <Tooltip
                                  title={childItem.tooltip}
                                  position="right"
                                  trigger="mouseenter"
                                  followCursor="true"
                                  delay={0}
                                  arrow={true}
                                  arrowSize={'small'}
                                  animation={'fade'}
                                  animateFill={false}
                                  transitionFlip={false}
                                  size={'small'}
                                >
                                  {GenerateMenuItemContent(
                                    childItem,
                                    index === menuItem.children.length - 1
                                  )}
                                </Tooltip>
                              )}
                              {!childItem.tooltip &&
                                GenerateMenuItemContent(
                                  childItem,
                                  index === menuItem.children.length - 1
                                )}
                            </MenuItem>
                          )
                        );
                      })}
                    </SubMenuContainer>
                  )}
                </MenuItem>
              );
            })}
          </MenuContent>
        </FlyOutMenu>
      ) : null}
    </Parent>
  );
};

const AssetMapBranding = styled.div`
  position: fixed;
  bottom: ${(props) => (props.isExpanded ? '0px' : '10px')};
  height: ${(props) => (props.isExpanded ? '45px' : null)};
  width: ${(props) =>
    props.isExpanded ? `calc(${props.sideNavWidth} - 30px)` : '45px'};
  padding: ${(props) => (props.isExpanded ? '5px 15px' : '5px 8px')};
  transform: ${(props) => (props.isExpanded ? null : 'rotate(270deg)')};
  p {
    font-size: ${(props) => (props.isExpanded ? '11px' : '8px')};
    font-weight: ${fonts.semiBold};
    color: ${colors.paleGrey};
    margin-bottom: ${(props) => (props.isExpanded ? '3px' : '1px')};
    width: ${(props) => (props.isExpanded ? 'auto' : '50px')};
  }
  img {
    width: ${(props) => (props.isExpanded ? '130px' : '80px')};
  }
`;

const Parent = styled.div`
  position: fixed;
  left: 0;
  z-index: 5;
  background: white;
`;

const FlyOutMenu = styled.div`
  @media ${maxDevice.sideNav} {
    top: 8px;
  }
  background: white;
  position: absolute;
  width: 150px;
  left: 59px;
  top: 52px;
  border-radius: 0 5px 5px 5px;
  box-shadow: ${boxShadows.boxShadow};
  z-index: 99;
  font-size: 12px;
  /* display: none; */
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: -6px;
    top: 10px;
    z-index: 100;
    border-top: 6px solid transparent;
    border-right: 6px solid ${colors.darkGrey};
    border-bottom: 6px solid transparent;
  }
`;

// const FlyoutContent = styled.div`
//   max-height: calc(100vh - 165px);
//   overflow: auto;
//   position: relative;
// `;

//LEFT SIDE NAV STYLES
const SideNavMenu = styled.div`
  @media (max-height: 650px) {
    ${AssetMapBranding} {
      display: none;
    }
  }
  border-right: 1px solid ${colors.lightGrey};
  height: calc(100vh - ${heights.navBar});
  flex: 0 0 auto;
  position: relative;
  /* overflow: auto; */
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  box-shadow: ${boxShadows.boxShadowSideNav};
  /* transition: width 0.15s ease-in-out; */
`;

const SideNavHeader = styled.div`
  @media ${maxDevice.sideNav} {
    display: ${(props) => (props.isConsumer ? 'flex' : 'none')};
  }
  height: 45px;
  background: ${colors.darkGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SideNavContents = styled.div`
  @media (max-height: 650px) {
    height: calc(100vh - ${heights.navBar} - ${heights.navBarHeading});
  }
  @media ${maxDevice.sideNav} {
    height: ${(props) =>
      `calc(100vh - ${heights.navBar} - ${props.brandingHeight})`};
  }
  padding: 0px;
  position: relative;
  overflow: auto;
  height: ${(props) =>
    `calc(100vh - ${heights.navBar} - ${heights.navBarHeading} - ${props.brandingHeight})`};
`;

const MenuIconContainer = styled.div`
  width: 44px;
  flex: 0 0 auto;
  height: 18px;
`;

const MenuIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const MenuIconExpand = styled(MenuIcon)`
  transform: rotate(180deg);
`;

const MenuButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
`;

const MenuText = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  margin-left: 10px;
`;

export const NavIcon = styled.img`
  flex: 0 0 auto;
  width: 22px;
  height: 22px;
  transition: all 0.1s ease;
  display: block;
`;

export const NavLabel = styled.div`
  margin-left: 20px;
  flex: 1 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: display 1s ease;
`;

export const ItemLabel = styled.span`
  flex: 1 1 auto;
  font-size: 14px;
  display: flex;
`;

// const BottomLinkContainer = styled(Link)`
//   display: flex;
//   align-content: center;
//   color: ${colors.darkGrey};
//   align-items: center;
//   justify-content: center;
//   padding: 12px 18px;
//   ${NavIcon} {
//     flex: 0 0 auto;
//   }
//   ${NavLabel} {
//     flex: 1 1 auto;
//   }
// `;

// const BottomNavItemContainer = styled.div`
//   height: 45px;
//   position: fixed;
//   bottom: 0px;
//   left: 0px;
//   background: white;
//   cursor: pointer;
//   border-top: 1px solid ${colors.lightGrey};
//   &:hover {
//     background: ${colors.lightGrey};
//     ${NavIcon} {
//       transform: scale(1.05);
//     }
//   }
// `;

SideNavContainer.propTypes = {
  isConsumer: PropTypes.bool,
};

export default SideNavContainer;
