import PropTypes from 'prop-types';
import { Handle, NodeToolbar, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { boxShadows, colors, fonts } from '../../styles/variables';
import {
  FlexCenterAll,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';
import { GenerateAvatarMemberIcon } from '../../utils';
import { pencil, timesDarkGrey, trashDark } from '../../assets';
import {
  getMemberInfo,
  toggleShowDeleteModal,
  toggleShowEntityModal,
  toggleShowMemberModal,
} from '../../store/actions';
import { useEffect } from 'react';

const handleStyle = { backgroundColor: colors.darkGrey };

const MemberNode = ({ data }) => {
  const dispatch = useDispatch();
  const { deleteElements, setNodes } = useReactFlow();
  const {
    currentHousehold,
    isConsumer,
    // invocationId
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    isConsumer: state.user.isConsumer,
    // invocationId: state.configs.invocationId,
  }));

  useEffect(() => {
    if (data.isNew) {
      setTimeout(() => {
        setNodes((nodes) =>
          [...nodes].map((value) => {
            const node = { ...value };
            if (node.id === data.id) {
              delete node.data.isNew;
            }
            return node;
          })
        );
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isNew]);

  const onDelete = (member) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        member.isMember
          ? member?.is_active
            ? 'member'
            : 'personal'
          : member?.is_active
            ? 'entity'
            : 'otherEntity',
        member.name,
        member.id
      )
    );
  };

  const onEdit = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id, isConsumer));
    member.isMember
      ? dispatch(toggleShowMemberModal(true, true, null, !member.is_active))
      : dispatch(toggleShowEntityModal(true, true, null, !member.is_active));
  };

  const onRemove = (member) => {
    deleteElements({ nodes: [{ id: member.id }] });
  };

  const toolBarActions = [
    {
      value: 'delete',
      icon: trashDark,
      onClick: onDelete,
      title: 'Delete Member from Household',
    },
    {
      value: 'edit',
      icon: pencil,
      onClick: onEdit,
      title: 'Edit Member Info',
    },
    {
      value: 'remove',
      icon: timesDarkGrey,
      onClick: onRemove,
      title: 'Remove from Map',
      style: { opacity: 0.4, width: '10px' },
    },
  ];

  return (
    <>
      <ToolbarContainer
        position={data.toolbarPosition}
        align="center"
        offset={10}
      >
        {toolBarActions.map((action) => {
          return (
            <ActionButton
              key={action.value}
              onClick={() => action.onClick(data)}
              id={`${action.value}-button`}
              title={action.title}
            >
              <img
                id={`${action.value}-icon`}
                src={action.icon}
                alt={action.value}
                data-image={`${action.value}-${data.id}`}
                style={{ ...action.style }}
              />
            </ActionButton>
          );
        })}
      </ToolbarContainer>
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        id="n"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={handleStyle}
        id="e"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={handleStyle}
        id="w"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        id="s"
      />
      <MemberNodeContainer $isNew={data.isNew}>
        {GenerateAvatarMemberIcon(data.category, data.isMember)}
        <MemberInfo>
          <MemberName>
            {data.name}
            {/* <MemberAge>{data.age}</MemberAge> */}
          </MemberName>
          <MemberCategory>{data.role}</MemberCategory>
        </MemberInfo>
      </MemberNodeContainer>
    </>
  );
};

const ToolbarContainer = styled(NodeToolbar)`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: ${boxShadows.boxShadowAMRSelected};
  border-radius: 50px;
  padding: 3px 2px;
`;

const ActionButton = styled.button`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin: 0 2px;
  position: relative;
  ${FlexCenterAll};
  img {
    width: 12px;
    height: 12px;
    opacity: 0.6;
  }
  &:hover {
    background: ${colors.lightGrey};
    transform: scale(1.01);
  }
`;

const MemberNodeContainer = styled.div`
  background: ${(props) => (props.$isNew ? colors.paleBlue : colors.white)};
  border: 1px solid ${colors.darkGrey};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  padding: 10px 20px 10px 10px;
  ${FlexCenterStart}
  width: 150px;
  font-size: 12px;
  color: ${colors.darkGrey};
  text-align: left;
  img,
  svg {
    width: 26px;
    height: 26px;
    flex: 0 0 auto;
  }
`;

const MemberInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
`;

const MemberCategory = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-transform: capitalize;
  ${
    '' /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  }
`;

// const MemberAge = styled.span`
//   font-weight: ${fonts.regular};
// `;

MemberNode.propTypes = {
  data: PropTypes.object,
};

export default MemberNode;
